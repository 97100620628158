import { globalSettings } from "./configuration";
import { getAuthToken } from "./browser_state";

const base_url = globalSettings.PORTAL_API_URL;

export const crmContactMessage = async ({subject, email, message}) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const body = new FormData();
    body.append("subject", subject);
    body.append("receiver", email);
    body.append("message", message)

    const response = await fetch(`${base_url}/api/messaging/`, {
        method: 'POST',
        headers: headers,
        redirect: 'follow',
        body
    })

    const referrals = await response.json();

    return referrals.results;
}
