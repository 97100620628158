import moment from "moment";

import { getAuthToken } from "./browser_state";
import { globalSettings } from "./configuration";

const base_url = globalSettings.PORTAL_API_URL;

export const getEmailTemplates = async () => {
    const authToken = await getAuthToken();
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/email_templates/`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const templates = await response.json();
    return templates.results;
}

export const saveEmailTemplate = async (title, subject, body) => {
    const authToken = await getAuthToken();
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);
    headers.append("Content-Type", "application/json");

    const templates = await getEmailTemplates();
    console.log(title, templates);
    const templateId = templates.find(t => t.name === title)?.id;

    const response = await fetch(`${base_url}/api/email_templates/${templateId}/`, {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify({ subject, body }),
        redirect: 'follow'
    });

    return response;
}