import { getAuthToken, setAuthToken, removeAuthToken } from "./browser_state";
import { createProfile } from "./profile";
import { globalSettings } from "./configuration";

const base_url = globalSettings.PORTAL_API_URL;

let isLoggedInCache = false;

export const isLoggedIn = () => new Promise(resolve => {
    getAuthToken()
        .then(async token => {
            return resolve(!!token);
            if (token) {
                // Check if the token is valid
                const profile = await createProfile(token);

                if(profile?.account_id[0] && profile?.account_id[0] == 'Invalid hyperlink - Object does not exist.') {
                    isLoggedInCache = false;
                    resolve(false);
                }

                if (!!profile) {
                    isLoggedInCache = true;
                    resolve(true);
                }
            }
        });
});

export const login = async ({ username, password, twoFactorCode }) => {
    if (!username || !password) {
        return false;
    }

    if (username.length === 0 || password.length === 0) {
        return false;
    }

    if (!!(await getAuthToken())) {
        return true;
    }

    const formdata = new FormData();
    formdata.append("username", username);
    formdata.append("password", password);

    if (!!twoFactorCode) {
        formdata.append("two_factor_code", twoFactorCode);
    }

    const requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api-token-auth/`, requestOptions);
        const data = await response.json();

        if (!!data['token']) {
            const user = await getUser(data['token']);

            if(user.is_superuser) {
                await setAuthToken(data.token);
                return true;
            }
        }
    
        return false;
    }
    catch (error) {
        return false;
    }
};

export const logout = async () => {
    await removeAuthToken();
    return true;
};

export const register = async ({ username, password, passwordConfirm, firstName, lastName, email, referralCode }) => {
    // endpoint: /api/accounts/register/
    const formdata = new FormData();
    formdata.append("username", username);
    formdata.append("password", password);
    formdata.append("password_confirm", passwordConfirm);
    formdata.append("name", `${firstName} ${lastName}`);
    formdata.append("email", email);
    formdata.append("referral_code", referralCode);

    const requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/accounts/register/`, requestOptions);
        const data = await response.json();

        if (!!data['id']) {
            return true;
        }

        const call_response = [];
        if(data['username']) {
            for(let i = 0; i < data['username'].length; i++) {
                call_response.push(data['username'][i]);
            }
        }
        if(data['password']) {
            for(let i = 0; i < data['password'].length; i++) {
                call_response.push(data['password'][i]);
            }
        }

        return call_response;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}

export const getUser = async (authToken = false) => {
    if(authToken == false) {
        authToken = await getAuthToken();
    }

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/users/`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const users = await response.json();

    return users[0];
}