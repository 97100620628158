import React, { act, useEffect, useState } from 'react';

import {
  ChakraProvider,
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Heading,
  Button,
  Select,
  Checkbox,
  Textarea,
  theme
} from '@chakra-ui/react'

import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
} from 'react-icons/fi'
import { IconType } from 'react-icons'

import { GridComponent, ColumnsDirective, ColumnDirective, Page, Toolbar, Search, Inject } from '@syncfusion/ej2-react-grids';

import moment from 'moment';

import { SidebarContent, NavItem, MobileNav } from './components/Layout';

import { getMarketingStatus } from './lib/marketing_status';
import { isLoggedIn } from './lib/authentication';
import { getProfile, toggle2faOnProfile, get2FAQRCode } from './lib/profile';
import { getVenues, getEvents, getEventInstances } from './lib/events';
import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';

const styles = {
    tabViewComponent: {
        marginTop: '20px'
    }
}

const prototypeActiveEvent = {
    id: 1,
    name: 'Test Event',
    start_date: moment().format('YYYY-MM-DD'),
    end_date: moment().add(1, 'days').format('YYYY-MM-DD'),
    location: 'Test Location',
    description: 'Test Description',
    status: 'active',
    attendees: 100,
    revenue: 1000,
    cost: 500,
    profit: 500,
    marketing_status: 'active'
}

const App = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [ loaded, setLoaded ] = useState(false);
    const [ activeEvent, setActiveEvent ] = useState(false);

    const [ events, setEvents ] = useState([]);
    const [ eventsLoaded, setEventsLoaded ] = useState(false);
    const [ eventVenues, setEventVenues ] = useState([]);
    const [ eventVenuesLoaded, setEventVenuesLoaded ] = useState(false);
    const [ eventInstances, setEventInstances ] = useState([]);
    const [ eventInstancesLoaded, setEventInstancesLoaded ] = useState(false);
  
    useEffect(() => {
        isLoggedIn().then((loggedIn) => {
            if(!loggedIn) {
                window.location.href = '/';
            }
        });

        if(!eventVenuesLoaded) {
            getVenues().then(venues => {
            if(!eventsLoaded) {
                getEvents().then(events => {
                setEvents(events.map(({
                    url,
                    name,
                    description,
                    start_date,
                    end_date,
                    venue,
                }) => {
                    return {
                    id: url.split('/')[url.split('/').length - 2],
                    venue: venues.find(({url}) => url === venue)?.name,
                    name,
                    description,
                    start_date,
                    end_date,
                    }
                }));
                setEventsLoaded(true);
                });
            };
    
            if(!eventInstancesLoaded) {
                getEventInstances().then(eventInstances => {
                setEventInstances(eventInstances.map(({
                    url,
                    name,
                    start_date,
                    end_date,
                    venue,
                }) => {
                    return {
                    id: url.split('/')[url.split('/').length - 2],
                    venue: venues.find(({url}) => url === venue)?.name,
                    name,
                    start_date,
                    end_date,
                    }
                }));
                });
                setEventInstancesLoaded(true);
            }
    
            setEventVenues(venues.map(({name}) => name));
            setEventVenuesLoaded(true); 
            setLoaded(true);
            });
        }
    });

    const toolbarOptions = ['Search'];
    const searchOptions = {
        fields: ['name', 'description', 'start_date', 'end_date', 'venue'],
        ignoreCase: true,
        operator: 'contains'
    };

    const PlanEventComponent = () => {
        return (
            <Box style={styles.tabViewComponent}>
                <Text>Plan Event Content</Text>
            </Box>
        )
    }

    const RunEventComponent = () => {
        return (
            <Box style={styles.tabViewComponent}>
                <Text>Run Event Content</Text>
            </Box>
        )
    }

    const CompleteEventComponent = () => {
        const [ listFinalised, setListFinalised ] = useState(false);

        const [delegates, setDelegates] = useState([
            { id: 1, name: 'John Doe', company: 'Company A', industry: 'Industry A', email: 'john@example.com', phone: '123-456-7890', hidden: false, includePhone: true, includeEmail: true },
            { id: 2, name: 'Jane Smith', company: 'Company B', industry: 'Industry B', email: 'jane@example.com', phone: '987-654-3210', hidden: false, includePhone: true, includeEmail: true },
        ]);

        const toggleHidden = (id) => {
            setDelegates(delegates.map(delegate => delegate.id === id ? { ...delegate, hidden: !delegate.hidden } : delegate));
        };

        const toggleIncludePhone = (id) => {
            setDelegates(delegates.map(delegate => delegate.id === id ? { ...delegate, includePhone: !delegate.includePhone } : delegate));
        }

        const toggleIncludeEmail = (id) => {
            setDelegates(delegates.map(delegate => delegate.id === id ? { ...delegate, includeEmail: !delegate.includeEmail } : delegate));
        }

        return (
            <Box style={styles.tabViewComponent}>
                { listFinalised && <Box>
                    <Text><strong>Event List Finalised</strong></Text>
                    <br />
                </Box> }
                <Box>
                    <Table variant="simple" mt={4}>
                        <Thead>
                            <Tr>
                                <Th>Name</Th>
                                <Th>Company</Th>
                                <Th>Industry</Th>
                                <Th>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {delegates.map(delegate => (
                                <Tr key={delegate.id} style={{
                                    backgroundColor: delegate.hidden ? 'gray' : 'transparent',
                                }}>
                                    <Td>{delegate.name}</Td>
                                    <Td>{delegate.company}</Td>
                                    <Td>{delegate.industry}</Td>
                                    <Td>{delegate.email}</Td>
                                    <Td>{delegate.phone}</Td>
                                    <Td>
                                        { !listFinalised && <>
                                            <Button size="xs" colorScheme="red" onClick={() => toggleHidden(delegate.id)}>
                                                {delegate.hidden ? 'Unhide' : 'Hide'}
                                            </Button>&nbsp;
                                            <Button size="xs" colorScheme="blue" onClick={() => toggleIncludeEmail(delegate.id)}>
                                                {delegate.includeEmail ? 'Exclude Email' : 'Include Email'}
                                            </Button>&nbsp;
                                            <Button size="xs" colorScheme="blue" onClick={() => toggleIncludePhone(delegate.id)}>
                                                {delegate.includePhone ? 'Exclude Phone' : 'Include Phone'}
                                            </Button>
                                        </> }
                                        { listFinalised && <>
                                            <Text>
                                                Hide on delegate list: <strong>{delegate.hidden ? "Yes" : "No" }</strong>&nbsp;
                                                Exclude Email: <strong>{!delegate.includeEmail ? "Yes" : "No" }</strong>&nbsp;
                                                Exclude Phone: <strong>{!delegate.includePhone ? "Yes" : "No" }</strong>&nbsp;
                                            </Text>
                                        </> }
                                    </Td>

                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>

                { !listFinalised && <Box>
                    <Button mt={4} colorScheme="blue" size="xs" onClick={() => setListFinalised(true)}>Finalise List</Button>
                </Box> }
            </Box>
        )
    }

    return (
        <ChakraProvider theme={theme}>
        <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
            <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
            <Drawer
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            returnFocusOnClose={false}
            onOverlayClick={onClose}
            size="full">
            <DrawerContent>
                <SidebarContent onClose={onClose} />
            </DrawerContent>
            </Drawer>
            {/* mobilenav */}
            <MobileNav onOpen={onOpen} />
            <Box ml={{ base: 0, md: 60 }} p="4">
            { activeEvent && <>
                <Heading as='h3' size='lg' mb={4}>
                    Event Composer: {activeEvent.name} &nbsp;&nbsp;
                    <Button colorScheme="blue" onClick={() => {
                        setActiveEvent(false);
                    }} size="xs">Close Event</Button>
                </Heading>
                </> }
            { !activeEvent && <><Heading as='h3' size='lg' mb={4}>Event Composer</Heading></> }
                <Flex>
                    { !loaded && <Text>...</Text> }
                    
                    { loaded && !activeEvent && <Box>
                        <Text style={{marginBottom: 10}}>Please select an event...</Text>
                        <GridComponent dataSource={eventInstances} allowPaging={true} pageSettings={{ pageSize: 25 }} toolbar={toolbarOptions} searchSettings={searchOptions}>
                            {/* Columns */}
                            <ColumnsDirective>
                            <ColumnDirective field='name' headerText='Name' width='100' textAlign='Left' />
                            <ColumnDirective field='description' headerText='Description' width='100' textAlign='Left' />
                            <ColumnDirective field='start_date' headerText='Start Date' width='100' textAlign='Left' />
                            <ColumnDirective field='end_date' headerText='End Date' width='100' textAlign='Left' />
                            <ColumnDirective field='venue' headerText='Venue' width='100' textAlign='Left' />
                            <ColumnDirective headerText='' width='100' textAlign='Center' template={row => (
                                <Button size="xs" colorScheme='blue' onClick={() => setActiveEvent(row)}>Select Event</Button>
                            )} />
                            </ColumnsDirective>
                            <Inject services={[Page, Toolbar, Search]}/>
                        </GridComponent>
                    </Box>}
                    
                    { loaded && activeEvent && <Box>
                        {/** syncfusion simpletabs component, 3 tabs, plan event, run event, complete event */}

                        <TabComponent>
                            <TabItemsDirective>
                                <TabItemDirective header={{ text: 'Plan Event' }} content={PlanEventComponent} />
                                <TabItemDirective header={{ text: 'Run Event' }} content={RunEventComponent} />
                                <TabItemDirective header={{ text: 'Complete Event' }} content={CompleteEventComponent} />
                            </TabItemsDirective>
                        </TabComponent>
                        
                    </Box>}
                </Flex>
            <Box mt={4}></Box>
            </Box>
        </Box>
        </ChakraProvider>
    )
}

export default App