import React, { useState, useEffect } from 'react';
import {
  ChakraProvider,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Avatar,
  FormControl,
  FormHelperText,
  InputRightElement,
  theme,
} from '@chakra-ui/react';
import { FaUserAlt, FaLock } from "react-icons/fa";

import { ColorModeSwitcher } from './ColorModeSwitcher';

import { login, isLoggedIn, getUser } from './lib/authentication';
import { getConfiguration } from './lib/configuration';
import { is2faEnabled } from './lib/profile';

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

function Login() {
  const [logoPath, setLogoPath] = useState('');
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginStage, setLoginStage] = useState(0);
  const [twoFactorCode, setTwoFactorCode] = useState("");

  useEffect(() => {
    isLoggedIn().then((loggedIn) => {
      console.log(loggedIn)
      if(loggedIn) {
        window.location.href = '/';
      }

      getConfiguration().then(({logo}) => {
        setLogoPath(logo);
      });
    });
  })

  const clickLogin = async (e) => {
    e.preventDefault();

    const account2faRequired = await is2faEnabled(emailAddress);
    if (account2faRequired) {
      setLoginStage(1);
    } else {
      await login({
        username: emailAddress,
        password
      });

      window.location.href = '/';
    }
  }

  const handleShowClick = () => setShowPassword(!showPassword);

  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3}>
          {/* <ColorModeSwitcher justifySelf="flex-end" /> */}
          <Text>&nbsp;</Text>
          <VStack spacing={8}>
            <Text>
            <Stack
              flexDir="column"
              mb="2"
              justifyContent="center"
              alignItems="center"
            >
              <Avatar size="2xl" src={logoPath} />
              {/* <Heading color="teal.400">Welcome</Heading> */}
              <Box minW={{ base: "90%", md: "468px" }}>
                <form>
                  { loginStage == 0 && <Stack
                      spacing={4}
                      p="1rem"
                      backgroundColor="whiteAlpha.900"
                      boxShadow="md"
                    >
                      <FormControl>
                        <InputGroup>
                          <InputLeftElement
                            pointerEvents="none"
                            children={<CFaUserAlt color="gray.300" />}
                          />
                          <Input type="text" placeholder="username / email address" value={emailAddress} onChange={e => setEmailAddress(e.target.value)}/>
                        </InputGroup>
                      </FormControl>
                      <FormControl>
                        <InputGroup>
                          <InputLeftElement
                            pointerEvents="none"
                            color="gray.300"
                            children={<CFaLock color="gray.300" />}
                          />
                          <Input
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                          />
                          <InputRightElement width="4.5rem">
                            <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                              {showPassword ? "Hide" : "Show"}
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                      </FormControl>
                      <Button
                        borderRadius={0}
                        type="submit"
                        variant="solid"
                        colorScheme="teal"
                        width="full"
                        onClick={clickLogin}
                      >
                        Login
                      </Button>
                    </Stack>
                  }
                  { loginStage == 1 && <>
                    <br />
                    <Text>Enter 2FA code</Text>
                    <br />
                    <Stack
                        spacing={4}
                        p="1rem"
                        backgroundColor="whiteAlpha.900"
                        boxShadow="md"
                      >
                      <FormControl>
                        <InputGroup style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}>
                          {Array.from({ length: 6 }).map((_, index) => (
                            <Input
                              key={index}
                              type="text"
                              maxLength="1"
                              width="3rem"
                              textAlign="center"
                              marginRight="0.5rem"
                              onChange={(e) => {
                                const value = e.target.value;
                                if (value.length === 1 && index < 5) {
                                  document.getElementById(`2fa-input-${index + 1}`).focus();
                                  document.getElementById(`2fa-input-${index + 1}`).value = '';
                                }
                                const newCode = twoFactorCode.split('');
                                newCode[index] = value;
                                setTwoFactorCode(newCode.join(''));
                              }}
                              id={`2fa-input-${index}`}
                            />
                          ))}
                        </InputGroup>
                      </FormControl>
                      <Button
                        borderRadius={0}
                        type="submit"
                        variant="solid"
                        colorScheme="teal"
                        width="full"
                        onClick={async (e) => {
                          e.preventDefault();
                          if (twoFactorCode.length === 6) {
                            await login({
                              username: emailAddress,
                              password,
                              twoFactorCode: twoFactorCode
                            });

                            window.location.href = '/';
                          } else {
                            alert('Invalid 2FA code');
                          }
                        }}
                      >
                        Submit
                      </Button>
                    </Stack>
                  </>
                  }
                </form>
              </Box>
            </Stack>
            </Text>
          </VStack>
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default Login;
