const dayNames = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

// Function to get the occurrence of the day in the month
function getDayOccurrenceInMonth(date) {
    const day = date.getDay(); // Day of the week (0=Sunday, 6=Saturday)
    const dayOfMonth = date.getDate(); // Day of the month

    // Calculate the occurrence of this day in the month
    const occurrence = Math.ceil(dayOfMonth / 7);
    
    // Check if it's the last occurrence of the day in the month
    const nextWeek = new Date(date);
    nextWeek.setDate(dayOfMonth + 7); // Move to the next week
    const isLast = nextWeek.getMonth() !== date.getMonth(); // If next week is a different month, it's the last occurrence

    if (isLast) {
        return "Last";
    } else if (occurrence === 1) {
        return "First";
    } else if (occurrence === 2) {
        return "Second";
    } else if (occurrence === 3) {
        return "Third";
    } else if (occurrence === 4) {
        return "Fourth";
    } else {
        return occurrence + "th";
    }
}

// Function to get day of the week as a string
function getDayOfWeekString(date) {
    const daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    return daysOfWeek[date.getDay()];
}

// Iterate over the next 60 days
function iterateNext60Days() {
    const dates = [];

    let startDate = new Date(); // Start from today
    for (let i = 0; i < 60; i++) {
        const currentDate = new Date();
        currentDate.setDate(startDate.getDate() + i); // Add 'i' days to the current date

        dates.push(currentDate);;
    }

    return dates;
}

export const eventTimePrediction = (start_date, end_date, end_time, rules) => {
    const events = [];

    iterateNext60Days().forEach((date) => {
        const dayOfWeek = getDayOfWeekString(date);
        const occurrence = getDayOccurrenceInMonth(date);

        if (date < new Date()) {
            return;
        }

        if (date < start_date) {
            return;
        }

        if (date > end_date) {
            return;
        }

        rules.forEach((rule) => {
            let addEvent = false;
            
            if ( rule.type == "Weekly" && rule.days.includes(dayOfWeek) ) {
                addEvent = true;
            }

            if (rule.days.includes(dayOfWeek) && rule.type === occurrence) {
                const event = new Date(date);
                addEvent = true;
            }

            if(addEvent) {
                const event = new Date(date);
                
                events.push(event);
            }
        });
    });

    if (events.length == 0) {
        events.push(start_date);
    }

    return events;
}