export const getAuthToken = async () => {
    return localStorage.getItem('authToken');
}

export const setAuthToken = async (token) => {
    localStorage.setItem('authToken', token);
}

export const removeAuthToken = async () => {
    localStorage.removeItem('authToken');
}

// Path: frontend/src/lib/configuration.js