import { getAuthToken } from "./browser_state";
import { globalSettings } from "./configuration";

const base_url = globalSettings.PORTAL_API_URL;

export const getMarketingStatus = async () => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/v1/marketing_status`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const status = await response.json();

    return status;
}