import React, { useEffect, useState } from 'react';

import {
  ChakraProvider,
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Heading,
  Button,
  Select,
  Checkbox,
  Textarea,
  theme
} from '@chakra-ui/react'

import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
} from 'react-icons/fi'
import { IconType } from 'react-icons'

import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, TrendlineDirective, TrendlinesDirective, Inject, Tooltip, LineSeries, ScatterSeries, SplineSeries, Trendlines, Category, DateTime } from '@syncfusion/ej2-react-charts';

import moment from 'moment';

import { SidebarContent, NavItem, MobileNav } from './components/Layout';

import { getMarketingStatus } from './lib/marketing_status';
import { isLoggedIn } from './lib/authentication';

const App = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  
  const [selectedAttendee, setSelectedAttendee] = useState('');
  const [selectedEventInstance, setSelectedEventInstance] = useState('');
  const [conflictMessage, setConflictMessage] = useState('');

  const [resolved, setResolved] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [savedConflict, setSavedConflict] = useState(null);

  const handleDescribeConflicts = () => {
    if (selectedAttendee === '1' && selectedEventInstance === '3') {
      setConflictMessage('Conflict with Alice Johnson. Please resolve.');
      return;
    }

    if (selectedAttendee === '2' && selectedEventInstance === '2') {
      setConflictMessage('Too many other attendees from same industry. Please resolve.');
      return;
    }

    setConflictMessage('No conflicts were found.');
  };

  const handleSaveConflict = () => {
    setSavedConflict({
      attendee: attendees.find(a => a.id === parseInt(selectedAttendee)).name,
      event: eventInstances.find(e => e.id === parseInt(selectedEventInstance)).name,
      resolved,
      additionalInfo
    });
    setConflictMessage('Conflict resolved.');
  };
  
  const [statusLoaded, setStatusLoaded] = useState(false);

  const attendees = [
      { id: 1, name: 'John Doe' },
      { id: 2, name: 'Jane Smith' },
      { id: 3, name: 'Alice Johnson' },
  ];

  const eventInstances = [
      { id: 1, name: 'Event 1' },
      { id: 2, name: 'Event 2' },
      { id: 3, name: 'Event 3' },
  ];

  useEffect(() => {
    isLoggedIn().then(loggedIn => {
      if(!loggedIn) {
        window.location.href = '/login';
      }
    });
  })

  return (
    <ChakraProvider theme={theme}>
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
        <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p="4">
          <Heading as='h3' size='lg' mb={4}>Conflict Manager</Heading>
          <Box>
            <VStack spacing={4} align="stretch">
              <Box>
                <Text mb="8px">Select Attendee:</Text>
                <Select
                  backgroundColor={useColorModeValue('white', 'gray.800')}
                  placeholder="Select an attendee"
                  value={selectedAttendee}
                  onChange={(e) => setSelectedAttendee(e.target.value)}
                >
                  {attendees.map((attendee) => (
                    <option key={attendee.id} value={attendee.id}>
                      {attendee.name}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box>
                <Text mb="8px">Select Event Instance:</Text>
                <Select
                  backgroundColor={useColorModeValue('white', 'gray.800')}
                  placeholder="Select an event instance"
                  value={selectedEventInstance}
                  onChange={(e) => setSelectedEventInstance(e.target.value)}
                >
                  {eventInstances.map((eventInstance) => (
                    <option key={eventInstance.id} value={eventInstance.id}>
                      {eventInstance.name}
                    </option>
                  ))}
                </Select>
              </Box>
              <Button colorScheme="teal" onClick={handleDescribeConflicts}>Describe Conflicts</Button>
                {conflictMessage && (
                  <Box mt={4}>
                    <Text>{conflictMessage}</Text>
                    {conflictMessage.includes('Conflict with') && (
                      <Box mt={4}>
                        <Checkbox backgroudColor='white' isChecked={resolved} onChange={(e) => setResolved(e.target.checked)}>
                          Mark as resolved
                        </Checkbox>
                        {resolved && (<>
                          <Textarea
                            backgroundColor='white'
                            mt={2}
                            placeholder="Additional information"
                            value={additionalInfo}
                            onChange={(e) => setAdditionalInfo(e.target.value)}
                          />
                          <Button mt={2} colorScheme="teal" onClick={handleSaveConflict}>
                            Submit
                          </Button>
                        </>)}
                      </Box>
                    )}
                    {conflictMessage.includes('Too many other attendees') && (
                      <Box mt={4}>
                        <Checkbox backgroudColor='white' isChecked={resolved} onChange={(e) => setResolved(e.target.checked)}>
                          Mark as resolved
                        </Checkbox>
                        {resolved && (<>
                          <Textarea
                            backgroundColor='white'
                            mt={2}
                            placeholder="Additional information"
                            value={additionalInfo}
                            onChange={(e) => setAdditionalInfo(e.target.value)}
                          />
                          <Button mt={2} colorScheme="teal" onClick={handleSaveConflict}>
                            Submit
                          </Button>
                        </>)}
                      </Box>
                    )}
                    {savedConflict && (
                      <Box mt={4}>
                        <Text>Conflict Details:</Text>
                        <Text>Attendee: {savedConflict.attendee}</Text>
                        <Text>Event: {savedConflict.event}</Text>
                        <Text>Resolved: {savedConflict.resolved ? 'Yes' : 'No'}</Text>
                        <Text>Additional Info: {savedConflict.additionalInfo}</Text>
                      </Box>
                    )}
                  </Box>
                )}
            </VStack>
          </Box>
          <Box mt={4}></Box>
        </Box>
      </Box>
    </ChakraProvider>
  )
}

export default App