import { useEffect, useState, useRef } from 'react';

import { useForm, Controller } from "react-hook-form";

import {
  ChakraProvider,
  Box,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  theme
} from '@chakra-ui/react'

import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
} from 'react-icons/fi'
import { IconType } from 'react-icons'

import { TextBoxComponent, NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars';

import { ScheduleComponent, Day, Week, WorkWeek, Month, Agenda, Inject } from '@syncfusion/ej2-react-schedule';

import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';
import { ColumnDirective, ColumnsDirective, GridComponent, Page, Search, Toolbar } from '@syncfusion/ej2-react-grids';
import { CheckBoxComponent, RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { ChartComponent } from '@syncfusion/ej2-react-charts';

import { StepperComponent, StepsDirective, StepDirective } from '@syncfusion/ej2-react-navigations';

import moment from 'moment';
import { useToast } from '@chakra-ui/react';

import { SidebarContent, NavItem, MobileNav } from './components/Layout';

import { isLoggedIn } from './lib/authentication';

import { getConfiguration, updateConfiguration } from './lib/configuration';

import { eventTimePrediction } from './lib/prediction';
import {
  getEventRegions,
  getEventLocations,
  getEventFormats,
  getVenues,
  createEvent,
  updateEvent as updateEventRecord,
  getEvents,
  searchEventInstances,
  getEventInstances,
  updateEventInstance
} from './lib/events';

const Help = () => {
  const [ recurring, setRecurring ] = useState(false);
  const [ recurringRules, setRecurringRules ] = useState([{
    type: "First", // weekly, first_weekday, second_weekday, third_weekday, fourth_weekday, last_weekday
    days: [],
  }]);

  const [ eventSchedule, setEventSchedule ] = useState([]);
  const [ eventScheduleLoaded, setEventScheduleLoaded ] = useState(false);

  const [ eventRegions, setEventRegions ] = useState([]);
  const [ eventLocations, setEventLocations ] = useState([]);
  const [ eventTypes, setEventTypes ] = useState([]);
  const [ eventVenues, setEventVenues ] = useState([]);

  const [ eventRegionsLoaded, setEventRegionsLoaded ] = useState(false);
  const [ eventLocationsLoaded, setEventLocationsLoaded ] = useState(false);
  const [ eventTypesLoaded, setEventTypesLoaded ] = useState(false);
  const [ eventVenuesLoaded, setEventVenuesLoaded ] = useState(false);

  const [ eventTypeStepEnabled, setEventTypeStepEnabled ] = useState(true);
  const [ eventDescriptionStepEnabled, setEventDescriptionStepEnabled ] = useState(false);
  const [ schedulingStepEnabled, setSchedulingStepEnabled ] = useState(false);
  const [ codeOfConductStepEnabled, setCodeOfConductStepEnabled ] = useState(false);
  const [ pricingStepEnabled, setPricingStepEnabled ] = useState(false);
  const [ confirmationStepEnabled, setConfirmationStepEnabled ] = useState(false);

  const [ events, setEvents ] = useState([]);
  const [ eventsLoaded, setEventsLoaded ] = useState(false);
  const [ eventInstances, setEventInstances ] = useState([]);
  const [ eventInstancesLoaded, setEventInstancesLoaded ] = useState(false);

  const toast = useToast()

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { 
    isOpen: createEventOpen,
    onOpen: onCreateEventOpen,
    onClose: onCreateEventClose
  } = useDisclosure();
  const [ activeModalStep, setActiveModalStep] = useState(0);

  const {
    handleSubmit: eventTypeDescription,
    control: controlEventType,
    watch: watchEventType,
    register: registerEventType,
    reset: resetEventType,
    formState: { errors: errorsEventType },
  } = useForm({
    mode: "onChange"
  });
  
  const {
    handleSubmit: handleSubmitEventDescription,
    control: controlEventDescription,
    watch: watchEventDescription,
    register: registerEventDescription,
    reset: resetEventDescription,
    formState: { errors: errorsEventDescription },
  } = useForm({
    mode: "onChange"
  });

  const {
    handleSubmit: handleSubmitScheduling,
    control: controlScheduling,
    watch: watchScheduling,
    register: registerScheduling,
    reset: resetScheduling,
    formState: { errors: errorsScheduling },
  } = useForm({
    mode: "onChange"
  });

  const {
    handleSubmit: handleSubmitCodeOfConduct,
    control: controlCodeOfConduct,
    watch: watchCodeOfConduct,
    register: registerCodeOfConduct,
    reset: resetCodeOfConduct,
    formState: { errors: errorsCodeOfConduct },
  } = useForm({
    mode: "onChange"
  });

  const {
    handleSubmit: handleSubmitPricing,
    control: controlPricing,
    watch: watchPricing,
    register: registerPricing,
    reset: resetPricing,
    formState: { errors: errorsPricing },
  } = useForm({
    mode: "onChange"
  });

  const eventType = {
    region: {
      label: "Region",
      type: "dropdown",
      placeholder: "Event Region",
      defaultValue: "",
      options: eventRegions,
      rules: {
        required: true,
      },
    },
    location: {
      label: "Event Location",
      type: "dropdown",
      placeholder: "Event Region",
      defaultValue: "",
      options: eventLocations,
      rules: {
        required: true,
      },
    },
    type: {
      label: "Type",
      type: "dropdown",
      placeholder: "Event Type",
      defaultValue: "",
      options: eventTypes,
      rules: {
        required: true,
      },
    },
    venue: {
      label: "Venue",
      type: "dropdown",
      placeholder: "Venue",
      defaultValue: "",
      options: eventVenues,
      rules: {
        required: true,
      },
    }
  }
  
  const eventCreateDescription = {
    name: {
      label: "Name",
      type: "text",
      placeholder: "Name of Event",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    description: {
      label: "Description",
      type: "textarea",
      placeholder: "Description of Event",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    image1: {
      label: "Image 1",
      type: "file",
      placeholder: "Image 1",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    image2: {
      label: "Image 2",
      type: "file",
      placeholder: "Image 2",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    image3: {
      label: "Image 3",
      type: "file",
      placeholder: "Image 3",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    image4: {
      label: "Image 4",
      type: "file",
      placeholder: "Image 4",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    image5: {
      label: "Image 5",
      type: "file",
      placeholder: "Image 5",
      defaultValue: "",
      rules: {
        required: true,
      },
    }
  }
  
  const scheduling = {
    startDate: {
      label: "Start Date",
      type: "datepicker",
      placeholder: "First Event Date",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    startTime: {
      label: "Start time",
      type: "timepicker",
      placeholder: "First Event Time",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    endDate: {
      label: "Stop Recurring on",
      type: "datepicker",
      placeholder: "Stop Recurring on",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    recurring: {
      label: "Recurring",
      type: "checkbox",
      placeholder: "Does the event repeat?",
      defaultValue: recurring,
      rules: {
        required: false,
      },
    },
  }

  const code_of_conduct = {
    maximum_industry_clash: {
      label: "Maximum Industry Clash",
      type: "number",
      placeholder: "Maximum Number of People from each industry",
      defaultValue: 100,
      rules: {
        required: true,
      },
    },
    codeOfConduct: {
      label: "Code of Conduct",
      type: "text",
      placeholder: "Code of Conduct",
      defaultValue: "https://berlincodeofconduct.org/",
      rules: {
        required: true,
      },
    }
  }

  const pricing = {
    ticketPriceMembers: {
      label: "Ticket Price - Members",
      type: "number",
      placeholder: "Ticket Price - Members",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    ticketPriceNonMembers: {
      label: "Ticket Price - Non Members",
      type: "number",
      placeholder: "Ticket Price - Non Members",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    creditPriceMembers: {
      label: "Credit Price - Members",
      type: "number",
      placeholder: "Credit Price - Members",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    creditPriceNonMembers: {
      label: "Credit Price - Non Members",
      type: "number",
      placeholder: "Credit Price - Non Members",
      defaultValue: "",
      rules: {
        required: true,
      },
    }
  }

  const stepper = useRef();

  watchScheduling(({recurring}) => {
    setRecurring(recurring);;
  });

  watchEventType(({region, location, type, venue}) => {
    setEventDescriptionStepEnabled(region && location && type && venue);
  });

  watchEventDescription(({name, description}) => {
    setSchedulingStepEnabled(name && description);
  });

  watchScheduling(({startDate, startTime, endDate}) => {
    setCodeOfConductStepEnabled(startDate && startTime && endDate);
  });

  watchCodeOfConduct(({maximum_industry_clash, codeOfConduct}) => {
    setPricingStepEnabled(codeOfConduct);
  });

  watchPricing(({ticketPriceMembers, ticketPriceNonMembers, creditPriceMembers, creditPriceNonMembers}) => {
    setConfirmationStepEnabled(ticketPriceMembers && ticketPriceNonMembers && creditPriceMembers && creditPriceNonMembers);
  });

  const Input = ({ value, onChange, type, ...rest }) => {
    switch (type) {
      case "text":
        return (
          <TextBoxComponent
            placeholder={rest?.placeholder}
            change={({ value }) => onChange(value)}
            value={value}
          />
        );
      case "number":
          return (
            <NumericTextBoxComponent
              placeholder={rest?.placeholder}
              change={({ value }) => onChange(value)}
              value={value}
            />
          );

        case "textarea":
          return (
            <TextBoxComponent
              multiline={true}
              placeholder={rest?.placeholder}
              change={({ value }) => onChange(value)}
              CssClass="e-bigger"
              value={value}
            />
          );

      case "radio":
        return rest?.options.map((e) => (
          <RadioButtonComponent
            key={e}
            label={e}
            value={e}
            onChange={(value) => onChange(value)}
            checked={value === e}
          />
        ));
      case "dropdown":
        return (
          <DropDownListComponent
            dataSource={rest?.options}
            allowFiltering={true}
            filterType="contains"
            select={({ itemData }) => {
              onChange(itemData.value);
            }}
            value={value}
          />
        );
   
      case "checkbox":
        return (
          <CheckBoxComponent
            label={rest?.checkboxLabel}
            onChange={(e) => onChange(e.target.checked)}
            checked={value}
          />
        );

      case "file":
        return (<>
          { value && <img src={value} style={{ maxWidth: 250 }} /> }
          <input
            type="file"
            onChange={(e) => {
              const file = e.target.files[0];
              const reader = new FileReader();
              reader.onload = (e) => {
                onChange(e.target.result);
              };
              reader.readAsDataURL(file);
            }}
          />
        </>);

      case "datepicker":
        return (
          <DatePickerComponent
            label={rest?.checkboxLabel}
            change={({ value }) => onChange(value)}
            value={value}
          />
        );

      case "timepicker":
        return (
          <TimePickerComponent
            label={rest?.checkboxLabel}
            change={({ value }) => onChange(value)}
            value={value}
          />
        );
   
      default:
        return null;
    }
  };
  
  const eventTypeInputs = Object.keys(eventType).map((e) => {
    const { rules, defaultValue, label } = eventType[e];
  
    return (
      <section style={{ marginBottom: 16, marginTop: 8 }} key={e}>
        <label><strong>{label}</strong></label>
        <Controller
          name={e}
          control={controlEventType}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div>
              <Input
                value={field.value}
                onChange={field.onChange}
                {...eventType[e]}
              />
            </div>
          )}
        />
        {/*errorsEventType[e] && <Error>This field is required</Error>*/}
      </section>
    );
  });

  const eventSchedulingInputs = Object.keys(scheduling).map((e) => {
    const { rules, defaultValue, label } = scheduling[e];
    
    return (
      <section style={{ marginBottom: 8 }} key={e}>
        <label><strong>{label}</strong></label>
        <Controller
          name={e}
          control={controlScheduling}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div>
              <Input
                value={field.value}
                onChange={field.onChange}
                {...scheduling[e]}
              />
            </div>
          )}
        />
        {/*errorsScheduling[e] && <Error>This field is required</Error>*/}
      </section>
    );
  });

  const eventCreateDescriptionInputs = Object.keys(eventCreateDescription).map((e) => {
    const { rules, defaultValue, label } = eventCreateDescription[e];
  
    return (
      <section style={{ marginBottom: 8 }} key={e}>
        <label><strong>{label}</strong></label>
        <Controller
          name={e}
          control={controlEventDescription}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div>
              <Input
                value={field.value}
                onChange={field.onChange}
                {...eventCreateDescription[e]}
              />
            </div>
          )}
        />
        {/*errorsEventDescription[e] && <Error>This field is required</Error>*/}
      </section>
    );
  });

  const codeOfConductInputs = Object.keys(code_of_conduct).map((e) => {
    const { rules, defaultValue, label } = code_of_conduct[e];

    return (
      <section style={{ marginBottom: 8 }} key={e}>
        <label><strong>{label}</strong></label>
        <Controller
          name={e}
          control={controlCodeOfConduct}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div>
              <Input
                value={field.value}
                onChange={field.onChange}
                {...code_of_conduct[e]}
              />
            </div>
          )}
        />
        {/*errorsCodeOfConduct[e] && <Error>This field is required</Error>*/}
      </section>
    );
  });

  const pricingInputs = Object.keys(pricing).map((e) => {
    const { rules, defaultValue, label } = pricing[e];

    return (
      <section style={{ marginBottom: 8 }} key={e}>
        <label><strong>{label}</strong></label>
        <Controller
          name={e}
          control={controlPricing}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div>
              <Input
                value={field.value}
                onChange={field.onChange}
                {...pricing[e]}
              />
            </div>
          )}
        />
        {/*errorsPricing[e] && <Error>This field is required</Error>*/}
      </section>
    );
  });

  const createConfirmedEvent = async () => {
    const event = {
      region: controlEventType._formValues.region,
      location: controlEventType._formValues.location,
      format: controlEventType._formValues.type,
      venue: controlEventType._formValues.venue,
      name: controlEventDescription._formValues.name,
      description: controlEventDescription._formValues.description,
      start_time: controlScheduling._formValues.startTime,
      start_date: controlScheduling._formValues.startDate,
      recurring: controlScheduling._formValues.recurring,
      end_date: controlScheduling._formValues.endDate,
      recurringRule: JSON.stringify(recurringRules),
      ticket_price_members: controlPricing._formValues.ticketPriceMembers,
      ticket_price_non_members: controlPricing._formValues.ticketPriceNonMembers,
      credit_price_members: controlPricing._formValues.creditPriceMembers,
      credit_price_non_members: controlPricing._formValues.creditPriceNonMembers,
    }

    console.log("Creating event", event);

    createEvent(event).then(() => {
      toast({
        title: "Event Created",
        description: "Event has been created",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      setEventVenuesLoaded(false);
      setEventsLoaded(false);

      // reset forms
      resetEventType();
      resetEventDescription();
      resetScheduling();
      resetPricing();

      setRecurringRules([{
        type: "First",
        days: [],
      }]);

      // set stepper to first step
      setActiveModalStep(0);
      
      onCreateEventClose();

      getVenues().then(venues => {
        if(!eventsLoaded) {
          getEvents().then(events => {
            setEvents(events.map(({
              url,
              name,
              description,
              start_date,
              end_date,
              venue,
            }) => {
              return {
                id: url.split('/')[url.split('/').length - 2],
                venue: venues.find(({url}) => url === venue)?.name,
                name,
                description,
                start_date,
                end_date,
              }
            }));
            setEventsLoaded(true);
          });

          getEventInstances().then(eventInstances => {
            setEventInstances(eventInstances.map(({
              url,
              name,
              start_date,
              end_date,
              venue,
            }) => {
              return {
                id: url.split('/')[url.split('/').length - 2],
                venue: venues.find(({url}) => url === venue)?.name,
                name,
                start_date,
                end_date,
              }
            }));
            setEventInstancesLoaded(true);
          })
        };

        setEventVenues(venues.map(({name}) => name));
        setEventVenuesLoaded(true); 
      });
    });
  }    

  useEffect(() => {
    isLoggedIn().then(loggedIn => {
      if(!loggedIn) {
        window.location.href = '/login';
      }
    });

    if(!eventRegionsLoaded) {
      getEventRegions().then(regions => {
        setEventRegions(regions.map(({name}) => name));
        setEventRegionsLoaded(true);

        console.log(eventRegions);
      });
    }

    if(!eventLocationsLoaded) {
      getEventLocations().then(locations => {
        setEventLocations(locations.map(({name}) => name));
        setEventLocationsLoaded(true);
      });
    };

    if(!eventTypesLoaded) {
      getEventFormats().then(types => {
        setEventTypes(types.map(({name}) => name));
        setEventTypesLoaded(true);
      });
    };

    if(!eventVenuesLoaded) {
      getVenues().then(venues => {
        if(!eventsLoaded) {
          getEvents().then(events => {
            setEvents(events.map(({
              url,
              name,
              description,
              start_date,
              end_date,
              venue,
            }) => {
              return {
                id: url.split('/')[url.split('/').length - 2],
                venue: venues.find(({url}) => url === venue)?.name,
                name,
                description,
                start_date,
                end_date,
              }
            }));
            setEventsLoaded(true);
          });
        };

        if(!eventInstancesLoaded) {
          getEventInstances().then(eventInstances => {
            setEventInstances(eventInstances.map(({
              url,
              name,
              start_date,
              end_date,
              venue,
            }) => {
              return {
                id: url.split('/')[url.split('/').length - 2],
                venue: venues.find(({url}) => url === venue)?.name,
                name,
                start_date,
                end_date,
              }
            }));
          });
          setEventInstancesLoaded(true);
        }

        setEventVenues(venues.map(({name}) => name));
        setEventVenuesLoaded(true); 
      });
    }

    if(!eventScheduleLoaded) {
      searchEventInstances().then(instances => {
        const processedInstances = instances.map(({
          name, start_date,
        }) => ({
          Subject: name,
          StartTime: new Date(start_date), 
          EndTime: new Date((new Date(start_date).setHours(new Date(start_date).getHours() + 2))),
          IsAllDay: false
        }));
        
        setEventSchedule(processedInstances);
        setEventScheduleLoaded(true);

        console.log(processedInstances);
      });
    }
  })

  const EventType = () => {
    return (<>
      {eventTypeInputs}
      { eventDescriptionStepEnabled && <Button style={{ marginLeft: 5 }} onClick={() => { setActiveModalStep(activeModalStep + 1); stepper.current.activeStep = stepper.current.activeStep + 1 }}>Next</Button> }
    </>)
  }

  const EventDescription = () => {
    return (<>
      {eventCreateDescriptionInputs}
      <Button onClick={() => { setActiveModalStep(activeModalStep - 1); stepper.current.activeStep = stepper.current.activeStep - 1 }}>Back</Button>
      { schedulingStepEnabled && <Button style={{ marginLeft: 5 }} onClick={() => { setActiveModalStep(activeModalStep + 1); stepper.current.activeStep = stepper.current.activeStep + 1 }}>Next</Button> }
    </>)
  }

  const Scheduling = () => {
    return (<>
      {eventSchedulingInputs}
      { recurring && <>
        <Heading as='h5' size='md'>Recurring Rules</Heading>
        {recurringRules.map((rule, index) => {
          return (<div key={`recurring_rules_{index}`}>
            <br /><label><strong>Frequency</strong></label><br />
            <DropDownListComponent
              dataSource={[
                { value: "Weekly", text: "Weekly" },
                { value: "First", text: "First" },
                { value: "Second", text: "Second" },
                { value: "Third", text: "Third" },
                { value: "Fourth", text: "Fourth" },
                { value: "Last", text: "Last" },
              ]}
              onChange={({value}) => {
                const newRules = [ ...recurringRules ];
                newRules[index].type = value;
                setRecurringRules(newRules);
              }}
              value={rule.type}
            />
            <div style={{ display: 'inline', marginRight: 20 }}></div>
            <CheckBoxComponent
              label="Monday"
              checked={rule.days.includes("monday")}
              change={({ checked }) => {
                const newRules = [ ...recurringRules ];
                newRules[index].days = checked ? [ ...rule.days, "monday" ] : rule.days.filter(e => e !== "monday");
                setRecurringRules(newRules);
              }}
            />
            <div style={{ display: 'inline', marginRight: 20 }}></div>
            <CheckBoxComponent
              label="Tuesday"
              checked={rule.days.includes("tuesday")}
              change={({ checked }) => {
                const newRules = [ ...recurringRules ];
                newRules[index].days = checked ? [ ...rule.days, "tuesday" ] : rule.days.filter(e => e !== "tuesday");
                setRecurringRules(newRules);
              }}
            />
            <div style={{ display: 'inline', marginRight: 20 }}></div>
            <CheckBoxComponent
              label="Wednesday"
              checked={rule.days.includes("wednesday")}
              change={({ checked }) => {
                const newRules = [ ...recurringRules ];
                newRules[index].days = checked ? [ ...rule.days, "wednesday" ] : rule.days.filter(e => e !== "wednesday");
                setRecurringRules(newRules);
              }}
            />
            <div style={{ display: 'inline', marginRight: 20 }}></div>
            <CheckBoxComponent
              label="Thursday"
              checked={rule.days.includes("thursday")}
              change={({ checked }) => {
                const newRules = [ ...recurringRules ];
                newRules[index].days = checked ? [ ...rule.days, "thursday" ] : rule.days.filter(e => e !== "thursday");
                setRecurringRules(newRules);
              }}
            />
            <div style={{ display: 'inline', marginRight: 20 }}></div>
            <CheckBoxComponent
              label="Friday"
              checked={rule.days.includes("friday")}
              change={({ checked }) => {
                const newRules = [ ...recurringRules ];
                newRules[index].days = checked ? [ ...rule.days, "friday" ] : rule.days.filter(e => e !== "friday");
                setRecurringRules(newRules);
              }}
            />
            <div style={{ display: 'inline', marginRight: 20 }}></div>
            <CheckBoxComponent
              label="Saturday"
              checked={rule.days.includes("saturday")}
              change={({ checked }) => {
                const newRules = [ ...recurringRules ];
                newRules[index].days = checked ? [ ...rule.days, "saturday" ] : rule.days.filter(e => e !== "saturday");
                setRecurringRules(newRules);
              }}
            />
            <div style={{ display: 'inline', marginRight: 20 }}></div>
            <CheckBoxComponent
              label="Sunday"
              checked={rule.days.includes("sunday")}
              change={({ checked }) => {
                const newRules = [ ...recurringRules ];
                newRules[index].days = checked ? [ ...rule.days, "sunday" ] : rule.days.filter(e => e !== "sunday");
                setRecurringRules(newRules);
              }}
            />
            <br /><br />
            { index > 0 && <Button style={{ marginRight: 5 }}  colorScheme='red' onClick={() => {
              const newRules = [ ...recurringRules ];
              newRules.splice(index, 1);
              setRecurringRules(newRules);
            }}>Remove Rule</Button> }
            <Button colorScheme='cyan' onClick={() => { setRecurringRules([ ...recurringRules, { type: "First", days: [] }]) }}>Add Rule</Button>
          </div>)
        })}
        <br />
        <br />
        
      </> }
      <Button onClick={() => { setActiveModalStep(activeModalStep - 1); stepper.current.activeStep = stepper.current.activeStep - 1 }}>Back</Button>
      { codeOfConductStepEnabled && <Button style={{ marginLeft: 5 }} onClick={() => { setActiveModalStep(activeModalStep + 1); stepper.current.activeStep = stepper.current.activeStep + 1 }}>Next</Button> }
    </>)
  }

  const CodeOfConduct = () => {
    return (<>
      {codeOfConductInputs}
      <Button onClick={() => { setActiveModalStep(activeModalStep - 1); stepper.current.activeStep = stepper.current.activeStep - 1 }}>Back</Button>
      { pricingStepEnabled && <Button style={{ marginLeft: 5 }} onClick={() => { setActiveModalStep(activeModalStep + 1); stepper.current.activeStep = stepper.current.activeStep + 1 }}>Next</Button> }
    </>)
  }

  const Pricing = () => {
    return (<>
      {pricingInputs}

      <Button onClick={() => { setActiveModalStep(activeModalStep - 1); stepper.current.activeStep = stepper.current.activeStep - 1 }}>Back</Button>
      { confirmationStepEnabled && <Button style={{ marginLeft: 5 }} onClick={() => { setActiveModalStep(activeModalStep + 1); stepper.current.activeStep = stepper.current.activeStep + 1 }}>Next</Button> }
    </>)
  }

  const Confirmation = () => {
    return (<>
      <Heading as='h3' size='lg'>Event Summary</Heading>

      <br />
      <Heading as='h5' size='md'>Event Type</Heading>

      <strong>Region:</strong> {controlEventType._formValues.region}<br />
      <strong>Location:</strong> {controlEventType._formValues.location}<br />
      <strong>Type:</strong> {controlEventType._formValues.type}<br />

      <br />
      <Heading as='h5' size='md'>Event Description</Heading>

      <strong>Event Name:</strong> {controlEventDescription._formValues.name}<br />
      <strong>Event Description:</strong> {controlEventDescription._formValues.description}<br />

      <br />
      <Heading as='h5' size='md'>Code of Conduct</Heading>

      <strong>Maximum Industry Clash:</strong> {controlCodeOfConduct._formValues.maximum_industry_clash}<br />
      <strong>Code of Conduct:</strong> {controlCodeOfConduct._formValues.codeOfConduct}<br />
      <br />
      
      <Heading as='h5' size='md'>Scheduling</Heading>
      
      <strong>Start Date:</strong> {moment(controlScheduling._formValues.startDate).format('dddd DD/MM/YYYY')}<br />
      <strong>Start Time:</strong> {moment(controlScheduling._formValues.startTime).format('hh:mm A')}<br />
      <strong>Recurring:</strong> {controlScheduling._formValues.recurring ? "Yes" : "No"}<br />
      
      <br />
      <Heading as='h5' size='md'>Future Events</Heading>
      
      <br />
      <Heading as='h6' size='sm'>Showing next 60 days</Heading>
      <br />
      <TableContainer>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th>Day of Week</Th>
              <Th>Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            { eventTimePrediction(controlScheduling._formValues.startDate, controlScheduling._formValues.endDate, controlScheduling._formValues.startTime, recurringRules).map(date => {
              return (<Tr>
                <Td>{moment(date).format('dddd')}</Td>
                <Td>{moment(date).format('DD/MM/YYYY')}</Td>
              </Tr>)
            }
            )}
          </Tbody>
        </Table>
      </TableContainer>

      <br /><br />

      <Button colorScheme="green" onClick={createConfirmedEvent}>Create Event</Button>
      <br /><br />

      <Button onClick={() => { setActiveModalStep(activeModalStep - 1); stepper.current.activeStep = stepper.current.activeStep - 1 }}>Back</Button>
    </>)
  }

  const [editEvent, setEditEvent] = useState(null);

  const handleEdit = (row) => {
    setEditEvent(row);

    getEvents().then(async events => {
      const regions = await getEventRegions();
      const locations = await getEventLocations();
      const types = await getEventFormats();
      const venues = await getVenues();

      const event = events.find(({url}) => url.split('/')[url.split('/').length - 2] === row.id);
      
      // update form values
      controlEventType._formValues.region = regions.find(({url}) => event.region == url).name;
      controlEventType._formValues.location = locations.find(({url}) => event.location == url).name;
      controlEventType._formValues.type = types.find(({url}) => event.format == url).name;
      controlEventType._formValues.venue = venues.find(({url}) => event.venue == url).name;
      setEventDescriptionStepEnabled(true);

      // update form values
      controlEventDescription._formValues.name = event.name;
      controlEventDescription._formValues.description = event.description;
      setSchedulingStepEnabled(true);
      
      // update form values
      controlScheduling._formValues.startDate = new Date(event.start_date);
      controlScheduling._formValues.startTime = new Date(event.start_time);
      controlScheduling._formValues.recurring = event.recurring;
      controlScheduling._formValues.endDate = new Date(event.end_date);
      setCodeOfConductStepEnabled(true);

      // update form values
      controlCodeOfConduct._formValues.maximum_industry_clash = 100;
      controlCodeOfConduct._formValues.codeOfConduct = "https://berlincodeofconduct.org/";
      setPricingStepEnabled(true);

      // update form values
      controlPricing._formValues.ticketPriceMembers = event.ticket_price_members;
      controlPricing._formValues.ticketPriceNonMembers = event.ticket_price_non_members;
      controlPricing._formValues.creditPriceMembers = event.credit_price_members;
      controlPricing._formValues.creditPriceNonMembers = event.credit_price_non_members;
      setConfirmationStepEnabled(true);

      // update stepper
      setActiveModalStep(0);

      // console.log(event, controlEventType._formValues)

      onEditEventOpen();
    });
  };

  const {
    isOpen: editEventOpen,
    onOpen: onEditEventOpen,
    onClose: onEditEventClose
  } = useDisclosure();

  const updateEvent = async () => {
    const updatedEvent = {
      ...editEvent,
      region: controlEventType._formValues.region,
      location: controlEventType._formValues.location,
      format: controlEventType._formValues.type,
      venue: controlEventType._formValues.venue,
      name: controlEventDescription._formValues.name,
      description: controlEventDescription._formValues.description,
      start_time: controlScheduling._formValues.startTime,
      start_date: controlScheduling._formValues.startDate,
      recurring: controlScheduling._formValues.recurring,
      end_date: controlScheduling._formValues.endDate,
      recurringRule: JSON.stringify(recurringRules),
      ticket_price_members: controlPricing._formValues.ticketPriceMembers,
      ticket_price_non_members: controlPricing._formValues.ticketPriceNonMembers,
      credit_price_members: controlPricing._formValues.creditPriceMembers,
      credit_price_non_members: controlPricing._formValues.creditPriceNonMembers,
    };

    console.log("Updating event", updatedEvent);

    updateEventRecord(updatedEvent).then(() => {
      toast({
        title: "Event Updated",
        description: "Event has been updated",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      setEventVenuesLoaded(false);
      setEventsLoaded(false);

      // reset forms
      resetEventType();
      resetEventDescription();
      resetScheduling();
      resetPricing();

      setRecurringRules([{
        type: "First",
        days: [],
      }]);

      // set stepper to first step
      setActiveModalStep(0);
      
      onEditEventClose();

      getVenues().then(venues => {
        if(!eventsLoaded) {
          getEvents().then(events => {
            setEvents(events.map(({
              url,
              name,
              description,
              start_date,
              end_date,
              venue,
            }) => {
              return {
                id: url.split('/')[url.split('/').length - 2],
                venue: venues.find(({url}) => url === venue)?.name,
                name,
                description,
                start_date,
                end_date,
              }
            }));
            setEventsLoaded(true);
          });

          getEventInstances().then(eventInstances => {
            setEventInstances(eventInstances.map(({
              url,
              name,
              start_date,
              end_date,
              venue,
            }) => {
              return {
                id: url.split('/')[url.split('/').length - 2],
                venue: venues.find(({url}) => url === venue)?.name,
                name,
                start_date,
                end_date,
              }
            }));
            setEventInstancesLoaded(true);
          })
        };

        setEventVenues(venues.map(({name}) => name));
        setEventVenuesLoaded(true); 
      });
    });
  };

  const toolbarOptions = ['Search'];
  const searchOptions = {
      fields: ['name', 'description', 'start_date', 'end_date', 'venue'],
      ignoreCase: true,
      operator: 'contains'
  };

  const {
    isOpen: editInstanceOpen,
    onOpen: onEditInstanceOpen,
    onClose: onEditInstanceClose
  } = useDisclosure();

  const updateInstance = async () => {
    const updatedInstance = {
      ...editEventInstance,
      id: editEventInstance.url.split('/')[editEventInstance.url.split('/').length - 2],
      region: controlEventType._formValues.region,
      location: controlEventType._formValues.location,
      format: controlEventType._formValues.type,
      venue: controlEventType._formValues.venue,
      name: controlEventDescription._formValues.name,
      description: controlEventDescription._formValues.description,
      start_time: controlScheduling._formValues.startTime,
      start_date: controlScheduling._formValues.startDate,
      end_date: controlScheduling._formValues.endDate,
      ticket_price_members: controlPricing._formValues.ticketPriceMembers,
      ticket_price_non_members: controlPricing._formValues.ticketPriceNonMembers,
      credit_price_members: controlPricing._formValues.creditPriceMembers,
      credit_price_non_members: controlPricing._formValues.creditPriceNonMembers,
    };

    updateEventInstance(updatedInstance).then(() => {
      toast({
        title: "Instance Updated",
        description: "Event instance has been updated",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      setEventVenuesLoaded(false);
      setEventsLoaded(false);

      // reset forms
      resetEventType();
      resetEventDescription();
      resetScheduling();
      resetPricing();

      // set stepper to first step
      setActiveModalStep(0);
      
      onEditInstanceClose();

      getVenues().then(venues => {
        if(!eventsLoaded) {
          getEvents().then(events => {
            setEvents(events.map(({
              url,
              name,
              description,
              start_date,
              end_date,
              venue,
            }) => {
              return {
                id: url.split('/')[url.split('/').length - 2],
                venue: venues.find(({url}) => url === venue)?.name,
                name,
                description,
                start_date,
                end_date,
              }
            }));
            setEventsLoaded(true);
          });

          getEventInstances().then(eventInstances => {
            setEventInstances(eventInstances.map(({
              url,
              name,
              start_date,
              end_date,
              venue,
            }) => {
              return {
                id: url.split('/')[url.split('/').length - 2],
                venue: venues.find(({url}) => url === venue)?.name,
                name,
                start_date,
                end_date,
              }
            }));
            setEventInstancesLoaded(true);
          })
        };

        setEventVenues(venues.map(({name}) => name));
        setEventVenuesLoaded(true); 
      });
    });
  };

  const [ editEventInstance, setEditEventInstance ] = useState(null);

  const handleEditInstance = (row) => {
    setEditEventInstance(row);

    getEventInstances().then(async eventInstances => {
      const regions = await getEventRegions();
      const locations = await getEventLocations();
      const types = await getEventFormats();
      const venues = await getVenues();

      const eventInstance = eventInstances.find(({url}) => url.split('/')[url.split('/').length - 2] === row.id);
      setEditEventInstance(eventInstance)
      
      // update form values
      try {
        controlEventType._formValues.region = regions.find(({url}) => eventInstance.event_region == url || url.split('/')[url.split('/').length - 2] == eventInstance.event_region.split('(')[0].split(')')[0]).name;
        controlEventType._formValues.location = locations.find(({url}) => eventInstance.event_location == url || url.split('/')[url.split('/').length - 2] == eventInstance.event_location.split('(')[0].split(')')[0]).name;
        controlEventType._formValues.type = types.find(({url}) => eventInstance.event_format == url || url.split('/')[url.split('/').length - 2] == eventInstance.event_format.split('(')[0].split(')')[0]).name;
        controlEventType._formValues.venue = venues.find(({url}) => eventInstance.event_venue == url || url.split('/')[url.split('/').length - 2] == eventInstance.event_venue.split('(')[0].split(')')[0]).name;
      } catch(e) {
        controlEventType._formValues.region = regions[0].name;
        controlEventType._formValues.location = locations[0].name;
        controlEventType._formValues.type = types[0].name;
        controlEventType._formValues.venue = venues[0].name;
      }

      setEventDescriptionStepEnabled(true);

      // update form values
      controlEventDescription._formValues.name = eventInstance.name;
      controlEventDescription._formValues.description = eventInstance.description;
      setSchedulingStepEnabled(true);
      
      // update form values
      controlScheduling._formValues.startDate = new Date(eventInstance.start_date);
      controlScheduling._formValues.startTime = new Date(eventInstance.start_time);
      controlScheduling._formValues.endDate = new Date(eventInstance.end_date);
      setCodeOfConductStepEnabled(true);

      // update form values
      controlCodeOfConduct._formValues.maximum_industry_clash = 100;
      controlCodeOfConduct._formValues.codeOfConduct = "https://berlincodeofconduct.org/";
      setPricingStepEnabled(true);

      // update form values
      controlPricing._formValues.ticketPriceMembers = eventInstance.ticket_price_members;
      controlPricing._formValues.ticketPriceNonMembers = eventInstance.ticket_price_non_members;
      controlPricing._formValues.creditPriceMembers = eventInstance.credit_price_members;
      controlPricing._formValues.creditPriceNonMembers = eventInstance.credit_price_non_members;
      setConfirmationStepEnabled(true);

      // update stepper
      setActiveModalStep(0);

      onEditInstanceOpen();
    });
  };

  return (<>
    <ChakraProvider theme={theme}>
      <Modal onClose={onCreateEventClose} isOpen={createEventOpen} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Event</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <StepperComponent 
              ref={stepper} created={() => { stepper.current.activeStep = activeModalStep }}
              stepChanged={args => setActiveModalStep(args.activeStep)}>
              <StepsDirective>
                <StepDirective disabled={!eventTypeStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Event Type' } />
                <StepDirective disabled={!eventDescriptionStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Event Description ' } />
                <StepDirective disabled={!schedulingStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Scheduling' } />
                <StepDirective disabled={!codeOfConductStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Code of Conduct' } />
                <StepDirective disabled={!pricingStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Pricing' } />
                <StepDirective disabled={!confirmationStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Confirmation' } />
              </StepsDirective>
            </StepperComponent>
            <Box marginTop={5}>
              {activeModalStep === 0 && <EventType />}
              {activeModalStep === 1 && <EventDescription />}
              {activeModalStep === 2 && <Scheduling />}
              {activeModalStep === 3 && <CodeOfConduct />}
              {activeModalStep === 4 && <Pricing />}
              {activeModalStep === 5 && <Confirmation />}
            </Box>

          </ModalBody>
          <ModalFooter>
            {/* <Button onClick={onCreateEventClose}>Close</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal onClose={onEditEventClose} isOpen={editEventOpen} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Event</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <StepperComponent 
              ref={stepper} created={() => { stepper.current.activeStep = activeModalStep }}
              stepChanged={args => setActiveModalStep(args.activeStep)}>
              <StepsDirective>
                <StepDirective disabled={!eventTypeStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Event Type' } />
                <StepDirective disabled={!eventDescriptionStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Event Description ' } />
                <StepDirective disabled={!schedulingStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Scheduling' } />
                <StepDirective disabled={!codeOfConductStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Code of Conduct' } />
                <StepDirective disabled={!pricingStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Pricing' } />
                <StepDirective disabled={!confirmationStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Confirmation' } />
              </StepsDirective>
            </StepperComponent>
            <Box marginTop={5}>
              {activeModalStep === 0 && <EventType />}
              {activeModalStep === 1 && <EventDescription />}
              {activeModalStep === 2 && <Scheduling />}
              {activeModalStep === 3 && <CodeOfConduct />}
              {activeModalStep === 4 && <Pricing />}
              {activeModalStep === 5 && <Confirmation />}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={updateEvent}>Update Event</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal onClose={onEditInstanceClose} isOpen={editInstanceOpen} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Event Instance</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <StepperComponent 
              ref={stepper} created={() => { stepper.current.activeStep = activeModalStep }}
              stepChanged={args => setActiveModalStep(args.activeStep)}>
              <StepsDirective>
                <StepDirective disabled={!eventTypeStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Event Type' } />
                <StepDirective disabled={!eventDescriptionStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Event Description ' } />
                <StepDirective disabled={!schedulingStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Scheduling' } />
                <StepDirective disabled={!codeOfConductStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Code of Conduct' } />
                <StepDirective disabled={!pricingStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Pricing' } />
                <StepDirective disabled={!confirmationStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Confirmation' } />
              </StepsDirective>
            </StepperComponent>
            <Box marginTop={5}>
              {activeModalStep === 0 && <EventType />}
              {activeModalStep === 1 && <EventDescription />}
              {activeModalStep === 2 && <Scheduling />}
              {activeModalStep === 3 && <CodeOfConduct />}
              {activeModalStep === 4 && <Pricing />}
              {activeModalStep === 5 && <Confirmation />}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={updateInstance}>Update Instance</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box minH="100vh" bgGradient='linear(to-b, gray.100, white)'>
        <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p="4">
          <Text>
            <Heading as='h3' size='lg' mb={4}>Events</Heading>
            <ScheduleComponent eventSettings={{ dataSource: eventSchedule }} currentView='Month'>
              <Inject services={[Day, Week, WorkWeek, Month, Agenda]}/>
            </ScheduleComponent>
            <br />
            <Button colorScheme='blue' onClick={onCreateEventOpen}>Create Event</Button>
            <br /><br />
            <Heading as='h3' size='lg' mb={4}>Event Collections</Heading>
            <GridComponent dataSource={events} allowPaging={true} pageSettings={{ pageSize: 10 }} toolbar={toolbarOptions} searchSettings={searchOptions}>
              <ColumnsDirective>
                <ColumnDirective field='name' headerText='Name' width='100' textAlign='Left' />
                <ColumnDirective field='description' headerText='Description' width='100' textAlign='Left' />
                <ColumnDirective field='start_date' headerText='Start Date' width='100' textAlign='Left' />
                <ColumnDirective field='end_date' headerText='End Date' width='100' textAlign='Left' />
                <ColumnDirective field='venue' headerText='Venue' width='100' textAlign='Left' />
                <ColumnDirective headerText='' width='100' textAlign='Center' template={row => (
                  <Button size="xs" colorScheme='blue' onClick={() => handleEdit(row)}>Edit</Button>
                )} />
              </ColumnsDirective>
              <Inject services={[Page, Toolbar, Search]}/>
              </GridComponent>
              </Text>
              </Box>

              <Box ml={{ base: 0, md: 60 }} p="4">
              <Text>
              <Heading as='h3' size='lg' mb={4}>Event Instances</Heading>
              <GridComponent dataSource={eventInstances} allowPaging={true} pageSettings={{ pageSize: 10 }} toolbar={toolbarOptions} searchSettings={searchOptions}>
              {/* Columns */}
              <ColumnsDirective>
                <ColumnDirective field='name' headerText='Name' width='100' textAlign='Left' />
                <ColumnDirective field='description' headerText='Description' width='100' textAlign='Left' />
                <ColumnDirective field='start_date' headerText='Start Date' width='100' textAlign='Left' />
                <ColumnDirective field='end_date' headerText='End Date' width='100' textAlign='Left' />
                <ColumnDirective field='venue' headerText='Venue' width='100' textAlign='Left' />
                <ColumnDirective headerText='' width='100' textAlign='Center' template={row => (
                  <Button size="xs" colorScheme='blue' onClick={() => handleEditInstance(row)}>Edit</Button>
                )} />
              </ColumnsDirective>
              <Inject services={[Page, Toolbar, Search]}/>
            </GridComponent>
          </Text>
        </Box>
      </Box>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </ChakraProvider>
  </>)
}

export default Help