import { useEffect, useState } from 'react';

import {
  ChakraProvider,
  IconButton,
  Box,
  Flex,
  HStack,
  VStack,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  Wrap,
  WrapItem,
  Heading,
  Button,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Input,
  Textarea,
  theme
} from '@chakra-ui/react'

import {
  MdPhone,
  MdEmail,
  MdLocationOn,
  MdFacebook,
  MdOutlineEmail,
} from 'react-icons/md'

import { BsGithub, BsDiscord, BsPerson } from 'react-icons/bs'

import { IconType } from 'react-icons'

import { SidebarContent, NavItem, MobileNav } from './components/Layout';

import { isLoggedIn } from './lib/authentication';
import { sendSupportMessage } from './lib/support';

const Help = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [ messageSent, setMessageSent ] = useState(false);
  const [ name, setName ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ message, setMessage ] = useState('');

  useEffect(() => {
    isLoggedIn().then(loggedIn => {
      if(!loggedIn) {
        window.location.href = '/login';
      }
    });
  })

  const sendEmail = async (e) => {
    e.preventDefault();
    
    sendSupportMessage({subject: `Support Request: ${name}`, email, message});
    
    setMessageSent(true);
  }

  return (
    <ChakraProvider theme={theme}>
      <Box minH="100vh" bgGradient='linear(to-b, gray.100, white)'>
        <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p={4}>
          <Flex justify="center">
            <Box
              bg="#02054B"
              color="white"
              borderRadius="lg"
              m={{ sm: 4, md: 16, lg: 10 }}
              p={{ sm: 5, md: 5, lg: 10 }}
              width="full">
              <Box p={4}>
                <Wrap spacing={{ base: 20, sm: 3, md: 5, lg: 20 }} justify="space-between">
                <WrapItem w="20%">
                    <Box>
                      <Heading>Help</Heading>
                      <Text mt={{ sm: 3, md: 3, lg: 5 }} color="gray.500">
                        Fill up the form below to contact
                      </Text>
                      <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                        <VStack pl={0} spacing={3} alignItems="flex-start">
                          <HStack>
                            <MdPhone color="#1970F1" size="20px" />
                            <Text size="md" variant="ghost" color="#DCE2FF">
                              +44 123 456 789
                            </Text>
                          </HStack>
                          <HStack>
                            <MdEmail color="#1970F1" size="20px" />
                            <Text size="md" variant="ghost" color="#DCE2FF">
                              hello@selwyn.com
                            </Text>
                          </HStack>
                          <HStack>
                            <MdLocationOn color="#1970F1" size="20px" />
                            <Text size="md" variant="ghost" color="#DCE2FF">
                              London, United Kingdom
                            </Text>
                          </HStack>
                        </VStack>
                      </Box>
                    </Box>
                  </WrapItem>
                  <WrapItem w="70%">
                    <Box bg="white" borderRadius="lg" width="full">
                      <Box m={8} color="#0B0E3F">
                      { messageSent
                        ? <Box>
                            <Text>Thanks, We'll get back to you as soon as possible.</Text>
                          </Box>
                        : <VStack spacing={5}>
                            <FormControl id="name">
                              <FormLabel>Your Name</FormLabel>
                              <InputGroup borderColor="#E0E1E7">
                                <Input type="text" size="md" value={name} onChange={e => setName(e.target.value)} />
                              </InputGroup>
                            </FormControl>
                            <FormControl id="email">
                              <FormLabel>Mail</FormLabel>
                              <InputGroup borderColor="#E0E1E7">
                                <Input type="text" size="md" value={email} onChange={e => setEmail(e.target.value)}/>
                              </InputGroup>
                            </FormControl>
                            <FormControl id="message">
                              <FormLabel>Message</FormLabel>
                              <Textarea
                                borderColor="gray.300"
                                _hover={{
                                  borderRadius: 'gray.300',
                                }}
                                placeholder="message"
                                value={message}
                                onChange={e => setMessage(e.target.value)}
                              />
                            </FormControl>
                            <FormControl id="submit" float="right">
                              <Button variant="solid" bg="#0D74FF" color="white" onClick={sendEmail}>
                                Send Message
                              </Button>
                            </FormControl>
                          </VStack>
                      }
                      </Box>
                    </Box>
                  </WrapItem>
                </Wrap>
              </Box>
            </Box>
          </Flex>
        </Box>
      </Box>
    </ChakraProvider>
  )
}

export default Help