import * as React from 'react';
import * as ReactDom from 'react-dom';

import { enableRipple } from '@syncfusion/ej2-base';
import { SpeedDialComponent } from '@syncfusion/ej2-react-buttons';

enableRipple(true);

function SpeedDial() {
    const items = [
        { text: 'Dashboard', triggerElement: () => { window.location.href = '/'; } },
        { text: 'Events', triggerElement: () => { window.location.href = '/events'; } },
        { text: 'Attendees', triggerElement: () => { window.location.href = '/attendees'; } },
        { text: 'Settings', triggerElement: () => { window.location.href = '/settings'; } },
    ];
    
    return (
      <SpeedDialComponent id='speeddial' items={items} content='Speed Dial' clicked={ (event) => {
        items.filter(({ text }) => text == event.item.properties.text)[0].triggerElement();
      } }/>
    );
}

export default SpeedDial;
