import React, { useEffect, useState } from 'react';

import {
  ChakraProvider,
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Heading,
  Button,
  Select,
  Checkbox,
  Textarea,
  theme
} from '@chakra-ui/react'

import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
} from 'react-icons/fi'
import { IconType } from 'react-icons'

import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, TrendlineDirective, TrendlinesDirective, Inject, Tooltip, LineSeries, ScatterSeries, SplineSeries, Trendlines, Category, DateTime } from '@syncfusion/ej2-react-charts';

import moment from 'moment';

import { SidebarContent, NavItem, MobileNav } from './components/Layout';

import { getMarketingStatus } from './lib/marketing_status';
import { isLoggedIn } from './lib/authentication';
import { getProfile, toggle2faOnProfile, get2FAQRCode } from './lib/profile';

const App = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [ enabled2fa, setEnabled2fa ] = useState(false);
  const [ qrCode, setQRCode ] = useState(false);
  const [ loaded, setLoaded ] = useState(false);
  
  useEffect(() => {
    isLoggedIn().then((loggedIn) => {
      if(!loggedIn) {
        window.location.href = '/';
      }
    });

    if(!loaded) {
        getProfile().then((profile) => {
            setEnabled2fa(profile.enabled_2fa);
            setLoaded(true);;
        });

        get2FAQRCode().then((qrCode) => {
            // convert data to base64 encoded image
            setQRCode('data:image/png;base64,' + qrCode);
        });
    }
  });

  return (
    <ChakraProvider theme={theme}>
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
        <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p="4">
          <Heading as='h3' size='lg' mb={4}>Setup 2FA</Heading>
            <Flex>
                { !loaded && <Text>...</Text> }
                { loaded && <>
                    { !enabled2fa ? <Box w="50%">
                        <Text>Two-factor authentication adds an extra layer of security to your account. Once enabled, you will be required to enter a code sent to your mobile device each time you log in.</Text>
                        { !!qrCode && <Box mt={4}>
                            <img width="300" src={qrCode} />
                        </Box> }
                        <Box mt={4}></Box>
                        <Button colorScheme="blue" onClick={() => {
                            toggle2faOnProfile();
                            setEnabled2fa(true);
                        }}>Enable 2FA</Button>
                    </Box> : <Box w="50%">
                        <Text>Two-factor authentication is enabled for your account. Please be sure to register the QR code before logging out.</Text>
                        { !!qrCode && <Box mt={4}>
                            <img width="300" src={qrCode} />
                        </Box> }
                        <Box mt={4}></Box>
                        <Button colorScheme="red"onClick={() => {
                            toggle2faOnProfile();
                            setEnabled2fa(false);
                        }}>Disable 2FA</Button>
                    </Box> }
                </>}
            </Flex>
          <Box mt={4}></Box>
        </Box>
      </Box>
    </ChakraProvider>
  )
}

export default App