import { useEffect, useState } from 'react';

import {
  ChakraProvider,
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Heading,
  theme
} from '@chakra-ui/react'

import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
} from 'react-icons/fi'
import { IconType } from 'react-icons'

import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';
import { ColumnDirective, ColumnsDirective, GridComponent, Page, Toolbar, Search } from '@syncfusion/ej2-react-grids';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, TrendlineDirective, TrendlinesDirective, Inject, Tooltip, LineSeries, ScatterSeries, SplineSeries, Trendlines, Category, DateTime } from '@syncfusion/ej2-react-charts';

import moment from 'moment';

import { SidebarContent, NavItem, MobileNav } from './components/Layout';

import { isLoggedIn } from './lib/authentication';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { getMarketingStatus } from './lib/marketing_status';

const Help = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [ statusLoaded, setStatusLoaded ] = useState(false);

  const [ contactsCreated, setContactsCreated ] = useState([]);
  const [ contactsUpdated, setContactsUpdated ] = useState([]);
  const [ contactsDeleted, setContactsDeleted ] = useState([]);

  const [ gridData, setGridData ] = useState([]);

  const primaryxAxis = { valueType: 'Category', intervalType: 'Days', edgeLabelPlacement: 'Shift', majorGridLines: { width: 0 } };
  const primaryyAxis = {
      title: 'Contacts Changed', interval: 25,
      lineStyle: { width: 0 }, majorTickLines: { width: 0 }
  };
  const tooltip = { enable: true };
  const chartarea = { border: { width: 0 } };
  const marker = { visible: false };

  const toolbarOptions = ['Search'];
  const searchOptions = {
      fields: ['firstName', 'lastName', 'email', 'external_id'],
      ignoreCase: true,
      operator: 'contains'
  };
  
  useEffect(() => {
    isLoggedIn().then(loggedIn => {
      if(!loggedIn) {
        window.location.href = '/login';
      }

      getMarketingStatus().then(status => {
        if (statusLoaded) {
          return;
        }

        const _contactsCreated = [];
        const _contactsUpdated = [];
        const _contactsDeleted = [];

        status.data.syncs.reverse().forEach((sync, key) => {
          _contactsCreated.push({ x: moment(sync.created_at).format('YYYY-MM-DD HH:mm:ss'), y: sync.contacts_created });
          _contactsUpdated.push({ x: moment(sync.created_at).format('YYYY-MM-DD HH:mm:ss'), y: sync.contacts_updated });
          _contactsDeleted.push({ x: moment(sync.created_at).format('YYYY-MM-DD HH:mm:ss'), y: sync.contacts_updated });
        });

        setContactsCreated(_contactsCreated);
        setContactsUpdated(_contactsUpdated);
        setContactsDeleted(_contactsDeleted);

        setGridData(status.data.contacts);

        setStatusLoaded(true);
      });
    });
  })

  return (
    <ChakraProvider theme={theme}>
      <Box minH="100vh" bgGradient='linear(to-b, gray.100, white)'>
        <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p="4">
          <Text>
            <Heading as='h3' size='lg' mb={4}>Marketing</Heading>

            <ButtonComponent onClick={() => alert('redirecting to brevo...')}>Marketing and Automation via Brevo</ButtonComponent>

            <p>&nbsp;</p>

            <ChartComponent id='charts' primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis} tooltip={tooltip} chartArea={chartarea} title='Recent Sync Jobs'>
              <Inject services={[Category, Tooltip, ScatterSeries, SplineSeries, LineSeries, Trendlines]}/>
              <SeriesCollectionDirective>
                <SeriesDirective dataSource={contactsCreated} xName='x' yName='y' name='Contacts Created' type='Line' fill='green'>
                  <TrendlinesDirective>
                    <TrendlineDirective type='MovingAverage' width={3} marker={marker}>
                    </TrendlineDirective>
                  </TrendlinesDirective>
                </SeriesDirective>
                <SeriesDirective dataSource={contactsUpdated} xName='x' yName='y' name='Contacts Updated' type='Line' fill='blue'>
                  <TrendlinesDirective>
                    <TrendlineDirective type='MovingAverage' width={3} marker={marker}>
                    </TrendlineDirective>
                  </TrendlinesDirective>
                </SeriesDirective>
                <SeriesDirective dataSource={contactsDeleted} xName='x' yName='y' name='Contacts Deleted' type='Line' fill='red'>
                  <TrendlinesDirective>
                    <TrendlineDirective type='MovingAverage' width={3} marker={marker}>
                    </TrendlineDirective>
                  </TrendlinesDirective>
                </SeriesDirective>
              </SeriesCollectionDirective>
            </ChartComponent>

            <p>&nbsp;</p>

            <Heading as='h5' size='md' mb={4}>Marketing Contacts</Heading>

            <GridComponent dataSource={gridData} allowPaging={true} pageSettings={{ pageSize: 10 }} toolbar={toolbarOptions} searchSettings={searchOptions}>
              <ColumnsDirective>
                <ColumnDirective field='first_name' headerText='First Name' />
                <ColumnDirective field='last_name' headerText='Last Name' />
                <ColumnDirective field='email' headerText='Email' />
                <ColumnDirective field='phone' headerText='Phone' />
                <ColumnDirective field='sms' headerText='SMS' />
                <ColumnDirective field='external_id' headerText='External ID' />
                <ColumnDirective field='created_at' headerText='Created At' />
              </ColumnsDirective>
              <Inject services={[Page, Toolbar, Search]}/>
            </GridComponent>
          </Text>
        </Box>
      </Box>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </ChakraProvider>
  )
}

export default Help