import moment from "moment";

import { getAuthToken } from "./browser_state";
import { globalSettings } from "./configuration";

const base_url = globalSettings.PORTAL_API_URL;

export const getCreditRules = async () => {
    const authToken = await getAuthToken();
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/credit_rules/`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })

    // return first item in results
    const rules = await response.json();
    return rules.results[0];
}

export const saveCreditRules = async (rule) => {
    const authToken = await getAuthToken();
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);
    headers.append("Content-Type", "application/json");

    const creditRules = await getCreditRules();
    const instanceId = creditRules.instance;

    creditRules.instance = instanceId;

    const response = await fetch(`${base_url}/api/credit_rules/${creditRules.id}/`, {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify(rule),
        redirect: 'follow'
    });

    return response;
}
