import { useEffect, useState } from 'react';

import {
  ChakraProvider,
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  theme
} from '@chakra-ui/react'

import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
} from 'react-icons/fi'
import { IconType } from 'react-icons'

import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';
import { ColumnDirective, ColumnsDirective, GridComponent, Edit, Inject, CommandColumn, Page, Search, Toolbar } from '@syncfusion/ej2-react-grids';
import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import { ChartComponent } from '@syncfusion/ej2-react-charts';

import { TextBoxComponent, NumericTextBoxComponent, TextAreaComponent } from "@syncfusion/ej2-react-inputs";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { CheckBoxComponent, RadioButtonComponent } from '@syncfusion/ej2-react-buttons';

import { useForm, Controller } from "react-hook-form";

import { SidebarContent, NavItem, MobileNav } from './components/Layout';

import { isLoggedIn } from './lib/authentication';
import { getProfileById, getProfiles, getProfileNotes, addProfileNote, deleteProfileNote } from './lib/profile';
import { FiPhone, FiPhoneCall } from 'react-icons/fi';
import { crmContactMessage } from './lib/email';

const Attendees = () => {
  const [ profiles, setProfiles ] = useState([]);
  const [ profilesLoaded, setProfilesLoaded ] = useState(false);

  const [ profile, setProfile ] = useState({});

  const [ profileNotes, setProfileNotes ] = useState([]);
  const [ profileNotesLoaded, setProfileNotesLoaded ] = useState(false);
  const [ newProfileNote, setNewProfileNote ] = useState('');
  const [ newProfileNoteStatus, setNewProfileNoteStatus ] = useState('');

  const [ selectedAttendee, setSelectedAttendee ] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure()
  
  const { 
    isOpen: editAttendeeOpen,
    onOpen: onEditAttendeeOpen,
    onClose: onEditAttendeeClose
  } = useDisclosure();

  const { 
    isOpen: contactAttendeeOpen,
    onOpen: onContactAttendeeOpen,
    onClose: onContactAttendeeClose
  } = useDisclosure();
  
  const {
    handleSubmit: handleSubmitAttendeeProfile,
    control: controlAttendeeProfile,
    watch: watchAttendeeProfile,
    register: registerAttendeeProfile,
    reset: resetAttendeeProfile,
    setValue: setValueAttendeeProfile,
    formState: { errors: errorsAttendeeProfile },
  } = useForm({
    mode: "onChange",
    defaultValues: async () => {
      return profile || {};
    }
  });

  useEffect(() => {
    isLoggedIn().then(loggedIn => {
      if(!loggedIn) {
        window.location.href = '/login';
      }
    });

    if(!profilesLoaded) {
      getProfiles().then(profiles => {
        setProfiles(profiles.filter(profile => !!profile.email).map(profile => ({
          id: profile.url.split('/')[profile.url.split('/').length - 2],
          name: profile.name,
          email: profile.email,
          company_name: profile.company_name,
          website: profile.website,
          landline: profile.landline,
          mobile: profile.mobile,
          description: profile.description,
          referral_code: profile.referral_code,
        })));

        setProfilesLoaded(true);
      })
    }
  })

  const attendeeProfileForm = {
    name: {
      label: "Name",
      type: "text",
      placeholder: "Name",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    email: {
      label: "Email",
      type: "text",
      placeholder: "Email",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    gender: {
      label: "Gender",
      type: "dropdown",
      placeholder: "",
      defaultValue: "",
      options: ['Male', 'Female', 'Other'],
      rules: {
        required: false,
      },
    },
    landline: {
      label: "Landline",
      type: "text",
      placeholder: "Landline",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    mobile: {
      label: "Mobile",
      type: "text",
      placeholder: "Mobile",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    country: {
      label: "Country",
      type: "dropdown",
      placeholder: "",
      defaultValue: "",
      options: ['United Kingdom', 'United States', 'Canada'],
      rules: {
        required: false,
      },
    },
    dietary: {
      label: "Dietary Requirements",
      type: "dropdown",
      placeholder: "",
      defaultValue: "",
      options: ['Vegetarian', 'Vegan', 'Halal', 'Kosher', 'Gluten Free', 'Dairy Free', 'Nut Free', 'Shellfish Free', 'Other'],
      rules: {
        required: false,
      },
    },
    status: {
      label: "Status",
      type: "dropdown",
      placeholder: "",
      defaultValue: "",
      options: ['Active', 'Inactive'],
      rules: {
        required: true,
      },
    },
    industry_sectors: {
      label: "Industry Sectors",
      type: "dropdown",
      placeholder: "",
      defaultValue: "",
      options: ['Aerospace', 'Agriculture', 'Automotive', 'Banking', 'Biotechnology', 'Chemical', 'Construction', 'Consumer Goods', 'Defense', 'Education', 'Energy', 'Entertainment', 'Financial Services', 'Food & Beverage', 'Healthcare', 'Hospitality', 'Insurance', 'Manufacturing', 'Media', 'Mining', 'Pharmaceutical', 'Real Estate', 'Retail', 'Software', 'Technology', 'Telecommunications', 'Transportation', 'Utilities'],
      rules: {
        required: false,
      },
    },
    company_name: {
      label: "Company Name",
      type: "text",
      placeholder: "Company Name",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    job_title: {
      label: "Job Title",
      type: "text",
      placeholder: "Job Title",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    business_description: {
      label: "Business Description",
      type: "textarea",
      placeholder: "Business Description",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    delegate_privacy: {
      label: 'Delegate Privacy',
      type: 'checkboxes',
      options: [
        'Do not appear on delegates list',
        'Remove landline',
        'Remove mobile',
        'Remove email'
      ],
      rules: {
        required: true,
      },
    },
    marketingPrivacy: {
      label: 'Marketing Privacy',
      type: 'checkboxes',
      options: [
        'Exclusive deals and promotions',
        'Announcements and breaking news',
        'Marketing emails'
      ],
      rules: {
        required: true,
      },
    }
  }

  const Input = ({ value, onChange, type, ...rest }) => {
    switch (type) {
      case "text":
        return (
          <TextBoxComponent
            placeholder={rest?.placeholder}
            disabled={!!rest?.disabled}
            change={({ value }) => onChange(value)}
            value={value}
          />
        );
        case "number":
          return (
            <NumericTextBoxComponent
              placeholder={rest?.placeholder}
              disabled={!!rest?.disabled}
              change={({ value }) => onChange(value)}
              value={value}
            />
          );

        case "textarea":
            return (
              <TextAreaComponent
              multiline={true}
              placeholder={rest?.placeholder}
              disabled={!!rest?.disabled}
              change={({ value }) => onChange(value)}
              CssClass="e-bigger"
              value={value}
              resizeMode='Vertical'
              style={{ height: 150 }}
              />
            );

      case "radio":
        return rest?.options.map((e) => (
          <RadioButtonComponent
            key={e}
            label={e}
            disabled={!!rest?.disabled}
            value={e}
            onChange={(value) => onChange(value)}
            checked={value === e}
          />
        ));
      case "dropdown":
        return (
          <DropDownListComponent
            dataSource={rest?.options}
            allowFiltering={true}
            filterType="contains"
            enabled={!!!rest?.disabled}
            select={({ itemData }) => {
              onChange(itemData.value);
            }}
            value={value}
          />
        );
   
      case "checkbox":
        return (
          <CheckBoxComponent
            label={rest?.checkboxLabel}
            disabled={!!rest?.disabled}
            onChange={(e) => onChange(e.target.checked)}
            checked={value}
          />
        );

      case "checkboxes":
        return (
          <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', marginTop: '10px' }}>
            {rest?.options.map((e) => (
              <CheckBoxComponent
                key={e}
                label={e}
                disabled={!!rest?.disabled}
                onChange={(e) => onChange(e.target.checked)}
                checked={value}
                style={{ marginBottom: '8px' }} />
            ))}
          </div>
        );
      
      case "datepicker":
        return (
          <DatePickerComponent
            label={rest?.checkboxLabel}
            disabled={!!rest?.disabled}
            change={({ value }) => onChange(value)}
            value={value}
          />
        );

      case "timepicker":
        return (
          <TimePickerComponent
            label={rest?.checkboxLabel}
            disabled={!!rest?.disabled}
            change={({ value }) => onChange(value)}
            value={value}
          />
        );
   
      default:
        return null;
    }
  };

  const AttendeeProfileInputs = () => (Object.keys(attendeeProfileForm).map((e) => {
    const { rules, defaultValue, label } = attendeeProfileForm[e];

    return (
      <section style={{ marginBottom: 8 }} key={e}>
        <label><strong>{label}</strong></label>
        <Controller
          name={e}
          control={controlAttendeeProfile}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div>
              <Input
                value={field.value}
                onChange={field.onChange}
                disabled={true}
                {...attendeeProfileForm[e]}
              />
            </div>
          )}
        />
        {/*errorsEventDescription[e] && <Error>This field is required</Error>*/}
      </section>
    );
  }));

  const ProfileNotes = () => {
    const createProfileNote = async () => {
      if(newProfileNote.length <= 3) {
        return;
      }

      await addProfileNote(profile.id, newProfileNote, newProfileNoteStatus);
      const notes = await getProfileNotes(profile.id);
      setProfileNotes(notes);

      setNewProfileNote('');
      setNewProfileNoteStatus('');
    }

    const deleteNote = async (noteToDelete) => {
      deleteProfileNote(noteToDelete);
      const notes = await getProfileNotes(profile.id);
      setProfileNotes(notes);
    }

    const statuses = [
      '',
      'Booked A Ticket',
      'Bought A Membership',
      'Took A Subscription',
      'Appointment',
      'Telephone Appointment',
      'Hot Lead',
      'Pipeline Specific Date',
      'Pipeline',
      'Decision Maker Info Request',
      'Non-Decision Maker Info Request',
      'Not Interested',
      'Not Relevant',
      'Invalid',
      'Duplicate',
      'Call Back',
    ];

    return (
      <VStack align="start" spacing={4}>
      <Heading size="md">Profile Notes</Heading>

      <Box width="100%">
      <DropDownListComponent
      dataSource={statuses}
      placeholder="Select Status"
      change={({ value }) => setNewProfileNoteStatus(value)}
      value={newProfileNoteStatus}
      />
      </Box>

      <Box width="100%">
      <Input type="textarea" value={newProfileNote} onChange={setNewProfileNote} placeholder="Add a new note..." />
      </Box>
      
      <Button colorScheme={newProfileNote.length > 3 ? "blue": "gray"} onClick={createProfileNote}>Add Note</Button>
      
      <VStack align="start" spacing={2} width="100%">
      {profileNotes.map((note, index) => (
      <Box 
        key={index} 
        p={4} 
        borderWidth="1px" 
        borderRadius="md" 
        width="100%" 
        bgGradient="linear(to-r, gray.100, gray.200)"
      >
        <Text fontWeight="bold">{note.note}</Text>
        <Text fontSize="sm" color="gray.500">{new Date(note.created_at).toLocaleString()}</Text>
        { note.status && <Text fontSize="sm" color="gray.500">Status: {note.status}</Text> }
        <Button colorScheme="red" size="sm" onClick={() => deleteNote(note.url)}>Delete</Button>
      </Box>
      ))}
      </VStack>
      </VStack>
    )
  }

  const AttendeeEvents = () => {
    const [ events, setEvents ] = useState([
      { name: 'Event 1', date: '2024-10-01', time: '10:00 AM', venue: 'London', format: 'In-Person', price: '£100', attended: false },
      { name: 'Event 2', date: '2024-11-15', time: '02:00 PM', venue: 'Manchester', format: 'Online', price: 'Free', attended: false },
      { name: 'Event 3', date: '2024-12-05', time: '09:00 AM', venue: 'Birmingham', format: 'In-Person', price: '£150', attended: false },
      { name: 'Event 4', date: '2024-12-20', time: '11:00 AM', venue: 'Leeds', format: 'Online', price: '£50', attended: false },
      { name: 'Event 5', date: '2024-01-10', time: '01:00 PM', venue: 'Glasgow', format: 'In-Person', price: '£200', attended: false },
      { name: 'Event 6', date: '2024-01-15', time: '10:00 AM', venue: 'London', format: 'In-Person', price: '£100', attended: true },
      { name: 'Event 7', date: '2023-02-20', time: '02:00 PM', venue: 'Manchester', format: 'Online', price: 'Free', attended: true },
      { name: 'Event 8', date: '2023-03-05', time: '09:00 AM', venue: 'Birmingham', format: 'In-Person', price: '£150', attended: true },
      { name: 'Event 9', date: '2023-04-10', time: '11:00 AM', venue: 'Leeds', format: 'Online', price: '£50', attended: true },
      { name: 'Event 10', date: '2023-05-15', time: '01:00 PM', venue: 'Glasgow', format: 'In-Person', price: '£200', attended: true },
      { name: 'Event 11', date: '2023-06-20', time: '10:00 AM', venue: 'London', format: 'In-Person', price: '£100', attended: true },
      { name: 'Event 12', date: '2023-07-25', time: '02:00 PM', venue: 'Manchester', format: 'Online', price: 'Free', attended: true },
      { name: 'Event 13', date: '2023-08-30', time: '09:00 AM', venue: 'Birmingham', format: 'In-Person', price: '£150', attended: true },
      { name: 'Event 14', date: '2023-09-05', time: '11:00 AM', venue: 'Leeds', format: 'Online', price: '£50', attended: true },
      { name: 'Event 15', date: '2023-09-20', time: '01:00 PM', venue: 'Glasgow', format: 'In-Person', price: '£200', attended: true },
      { name: 'Event 16', date: '2023-09-25', time: '10:00 AM', venue: 'London', format: 'In-Person', price: '£100', attended: true },
      { name: 'Event 17', date: '2023-09-30', time: '02:00 PM', venue: 'Manchester', format: 'Online', price: 'Free', attended: true },
      { name: 'Event 18', date: '2023-09-10', time: '09:00 AM', venue: 'Birmingham', format: 'In-Person', price: '£150', attended: true },
      { name: 'Event 19', date: '2023-08-15', time: '11:00 AM', venue: 'Leeds', format: 'Online', price: '£50', attended: true },
      { name: 'Event 20', date: '2023-07-10', time: '01:00 PM', venue: 'Glasgow', format: 'In-Person', price: '£200', attended: true },
      { name: 'Event 21', date: '2024-02-15', time: '10:00 AM', venue: 'Edinburgh', format: 'In-Person', price: '£120', attended: false },
      { name: 'Event 22', date: '2024-03-20', time: '02:00 PM', venue: 'Cardiff', format: 'Online', price: 'Free', attended: false },
      { name: 'Event 23', date: '2024-04-25', time: '09:00 AM', venue: 'Bristol', format: 'In-Person', price: '£180', attended: false },
      { name: 'Event 24', date: '2024-05-30', time: '11:00 AM', venue: 'Liverpool', format: 'Online', price: '£60', attended: false },
      { name: 'Event 25', date: '2024-06-10', time: '01:00 PM', venue: 'Newcastle', format: 'In-Person', price: '£220', attended: false },
    ]);

    const currentDate = new Date();

    const futureEvents = events.filter(event => new Date(event.date) >= currentDate);
    const pastEvents = events.filter(event => new Date(event.date) < currentDate);

    const toggleAttendance = (index) => {
      const updatedEvents = [...pastEvents];
      updatedEvents[index].attended = !updatedEvents[index].attended;
      setEvents([...futureEvents, ...updatedEvents]);
    };

    return (
      <VStack align="start" spacing={4}>
      <Heading size="md">Future Events</Heading>
      <Box width="100%" overflowX="auto">
        {futureEvents.length > 0 ? (
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
          <tr>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Name</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Date</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Time</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Venue</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Format</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Price</th>
          </tr>
          </thead>
          <tbody>
          {futureEvents.map((event, index) => (
            <tr key={index}>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{event.name}</td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{event.date}</td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{event.time}</td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{event.venue}</td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{event.format}</td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{event.price}</td>
            </tr>
          ))}
          </tbody>
        </table>
        ) : (
        <Text>No future events.</Text>
        )}
      </Box>

      <Heading size="md" mt={8}>Past Events</Heading>
      <Box width="100%" overflowX="auto">
        {pastEvents.length > 0 ? (
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
          <tr>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Name</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Date</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Time</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Venue</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Format</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Price</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Attendance</th>
          </tr>
          </thead>
          <tbody>
          {pastEvents.map((event, index) => (
            <tr key={index}>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{event.name}</td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{event.date}</td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{event.time}</td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{event.venue}</td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{event.format}</td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{event.price}</td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              <Button colorScheme={event.attended ? "green" : "red"} onClick={() => toggleAttendance(index)}>
              {event.attended ? "Attended" : "Not Attended"}
              </Button>
            </td>
            </tr>
          ))}
          </tbody>
        </table>
        ) : (
        <Text>No past events.</Text>
        )}
      </Box>
      </VStack>
    );
  }

  const AttendeeMemberships = () => {
    const [memberships, setMemberships] = useState([
      { type: 'Bronze', startDate: '2023-01-01', renewalDate: '2024-01-01', description: 'Basic membership with limited benefits.' },
      { type: 'Silver', startDate: '2023-02-01', renewalDate: '2024-02-01', description: 'Intermediate membership with additional benefits.' },
      { type: 'Gold', startDate: '2023-03-01', renewalDate: '2024-03-01', description: 'Premium membership with extensive benefits.' },
      { type: 'Titanium', startDate: '2023-04-01', renewalDate: '2024-04-01', description: 'Elite membership with all benefits.' },
    ]);

    const getColorForMembership = (type) => {
      switch (type) {
      case 'Bronze':
        return 'orange.200';
      case 'Silver':
        return 'gray.300';
      case 'Gold':
        return 'yellow.300';
      case 'Titanium':
        return 'blue.300';
      default:
        return 'gray.200';
      }
    };

    return (
      <VStack align="start" spacing={4}>
      <Heading size="md">Memberships</Heading>
      {memberships.length > 0 ? (
        memberships.map((membership, index) => (
        <Box
          key={index}
          p={4}
          borderWidth="1px"
          borderRadius="md"
          width="100%"
          bg={getColorForMembership(membership.type)}
        >
          <Text fontWeight="bold">{membership.type}</Text>
          <Text>Start Date: {membership.startDate}</Text>
          <Text>Renewal Date: {membership.renewalDate}</Text>
          <Text>Description: {membership.description}</Text>
        </Box>
        ))
      ) : (
        <Text>No memberships found.</Text>
      )}
      </VStack>
    );
  };

  const AttendeeConflicts = () => {
    const [signedUpEvents, setSignedUpEvents] = useState([
      { name: 'Event 1', date: '2024-10-01', time: '10:00 AM' },
      { name: 'Event 2', date: '2024-10-01', time: '10:00 AM' }, // Conflict with Event 1
      { name: 'Event 3', date: '2024-11-15', time: '02:00 PM' },
      { name: 'Event 4', date: '2024-11-15', time: '02:00 PM' }, // Conflict with Event 3
      { name: 'Event 5', date: '2024-12-05', time: '09:00 AM' },
      { name: 'Event 6', date: '2024-12-05', time: '09:00 AM' }, // Conflict with Event 5
      { name: 'Event 7', date: '2024-12-20', time: '11:00 AM' },
      { name: 'Event 8', date: '2024-01-10', time: '01:00 PM' },
      { name: 'Event 9', date: '2024-01-15', time: '10:00 AM' },
      { name: 'Event 10', date: '2024-02-20', time: '02:00 PM' },
      { name: 'Event 11', date: '2024-03-05', time: '09:00 AM' },
      { name: 'Event 12', date: '2024-04-10', time: '11:00 AM' },
      { name: 'Event 13', date: '2024-05-15', time: '01:00 PM' },
      { name: 'Event 14', date: '2024-06-20', time: '10:00 AM' },
      { name: 'Event 15', date: '2024-07-25', time: '02:00 PM' },
    ]);

    const checkConflicts = () => {
      const conflicts = [];
      const eventMap = new Map();

      signedUpEvents.forEach((event, index) => {
        const eventDateTime = `${event.date}T${event.time}`;
        if (eventMap.has(eventDateTime)) {
          conflicts.push(index);
          conflicts.push(eventMap.get(eventDateTime));
        } else {
          eventMap.set(eventDateTime, index);
        }
      });

      return conflicts;
    };

    const conflicts = checkConflicts();

    return (
      <VStack align="start" spacing={4}>
      <Heading size="md">Conflicts</Heading>
      
      {conflicts.length > 0 ? (
        conflicts.map((conflictIndex, index) => (
          <Box
            key={index}
            p={4}
            borderWidth="1px"
            borderRadius="md"
            width="100%"
            bg="red.200"
          >
            <Text fontWeight="bold">{signedUpEvents[conflictIndex].name}</Text>
            <Text>Date: {signedUpEvents[conflictIndex].date}</Text>
            <Text>Time: {signedUpEvents[conflictIndex].time}</Text>
            <Text>Conflicts With: {conflicts.filter(i => i !== conflictIndex && signedUpEvents[i].date === signedUpEvents[conflictIndex].date && signedUpEvents[i].time === signedUpEvents[conflictIndex].time).map(i => signedUpEvents[i].name).join(', ')}</Text>
          </Box>
        ))
      ) : (
        <Text>No conflicts found.</Text>
      )}

      <Box width="100%" overflowX="auto">
      {signedUpEvents.length > 0 ? (
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
        <tr>
        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Name</th>
        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Date</th>
        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Time</th>
        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Conflicts With</th>
        </tr>
        </thead>
        <tbody>
        {signedUpEvents.map((event, index) => {
        const conflictIndexes = conflicts.filter(conflictIndex => conflictIndex !== index && signedUpEvents[conflictIndex].date === event.date && signedUpEvents[conflictIndex].time === event.time);
        return (
          <tr key={index} style={{ backgroundColor: conflictIndexes.length > 0 ? 'red' : 'green' }}>
          <td style={{ border: '1px solid #ddd', padding: '8px' }}>{event.name}</td>
          <td style={{ border: '1px solid #ddd', padding: '8px' }}>{event.date}</td>
          <td style={{ border: '1px solid #ddd', padding: '8px' }}>{event.time}</td>
          <td style={{ border: '1px solid #ddd', padding: '8px' }}>
            {conflictIndexes.length > 0 ? conflictIndexes.map(conflictIndex => signedUpEvents[conflictIndex].name).join(', ') : 'None'}
          </td>
          </tr>
        );
        })}
        </tbody>
      </table>
      ) : (
      <Text>No events.</Text>
      )}
      </Box>
      </VStack>
    );
  };

  const AttendeePayments = () => {
    const [payments, setPayments] = useState([
      { date: '2023-01-01', reference: 'REF001', type: 'Bronze', amount: 10 },
      { date: '2023-02-01', reference: 'REF002', type: 'Silver', amount: 20 },
      { date: '2023-03-01', reference: 'REF003', type: 'Gold', amount: 30 },
      { date: '2023-04-01', reference: 'REF004', type: 'Platinum', amount: 40 },
      { date: '2023-05-01', reference: 'REF005', type: 'One-off', amount: 50 },
      { date: '2023-06-01', reference: 'REF006', type: 'Bronze', amount: 10 },
      { date: '2023-07-01', reference: 'REF007', type: 'Silver', amount: 20 },
      { date: '2023-08-01', reference: 'REF008', type: 'Gold', amount: 30 },
      { date: '2023-09-01', reference: 'REF009', type: 'Platinum', amount: 40 },
      { date: '2023-10-01', reference: 'REF010', type: 'One-off', amount: 50 },
      { date: '2023-11-01', reference: 'REF011', type: 'Bronze', amount: 10 },
      { date: '2023-12-01', reference: 'REF012', type: 'Silver', amount: 20 },
      { date: '2024-01-01', reference: 'REF013', type: 'Gold', amount: 30 },
      { date: '2024-02-01', reference: 'REF014', type: 'Platinum', amount: 40 },
      { date: '2024-03-01', reference: 'REF015', type: 'One-off', amount: 50 },
      { date: '2024-04-01', reference: 'REF016', type: 'Bronze', amount: 10 },
      { date: '2024-05-01', reference: 'REF017', type: 'Silver', amount: 20 },
      { date: '2024-06-01', reference: 'REF018', type: 'Gold', amount: 30 },
      { date: '2024-07-01', reference: 'REF019', type: 'Platinum', amount: 40 },
      { date: '2024-08-01', reference: 'REF020', type: 'One-off', amount: 50 },
      { date: '2024-09-01', reference: 'REF021', type: 'Bronze', amount: 10 },
      { date: '2024-10-01', reference: 'REF022', type: 'Silver', amount: 20 },
      { date: '2024-11-01', reference: 'REF023', type: 'Gold', amount: 30 },
      { date: '2024-12-01', reference: 'REF024', type: 'Platinum', amount: 40 },
      { date: '2025-01-01', reference: 'REF025', type: 'One-off', amount: 50 },
    ]);

    const totalPayments = payments.reduce((sum, payment) => sum + payment.amount, 0);
    const totalCredits = Math.floor(totalPayments / 10);

    const sortedPayments = [...payments].sort((a, b) => new Date(b.date) - new Date(a.date));

    return (
      <VStack align="start" spacing={4}>
      <Heading size="md">Payments</Heading>
      
      <Box mt={4} p={4} borderWidth="1px" borderRadius="md" width="100%" bg="gray.100">
      <Text><strong>Total Payments:</strong> £{totalPayments}</Text>
      <Text><strong>Total Credits:</strong> {totalCredits}</Text>
      </Box>

      <Box width="100%" overflowX="auto">
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr>
        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Date</th>
        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Reference</th>
        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Type</th>
        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Amount (£)</th>
        </tr>
      </thead>
      <tbody>
        {sortedPayments.map((payment, index) => (
        <tr key={index}>
        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{payment.date}</td>
        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{payment.reference}</td>
        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{payment.type}</td>
        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{payment.amount}</td>
        </tr>
        ))}
      </tbody>
      </table>
      </Box>
      </VStack>
    );
  };

  const AttendeeReferrals = () => {
    const [referrals, setReferrals] = useState([
      { date: '2023-01-01', name: 'John Doe' },
      { date: '2023-02-15', name: 'Jane Smith' },
      { date: '2023-03-10', name: 'Alice Johnson' },
      { date: '2023-04-05', name: 'Bob Brown' },
    ]);

    const totalCredits = referrals.length;

    return (
      <VStack align="start" spacing={4}>
        <Heading size="md">Referrals</Heading>
        
        <Box mt={4} p={4} borderWidth="1px" borderRadius="md" width="100%" bg="gray.100">
          <Text><strong>Referral Code:</strong> {profile.referral_code}</Text>
          <Text><strong>Total Credits Gained:</strong> {totalCredits}</Text>
        </Box>

        <Box width="100%" overflowX="auto">
          {referrals.length > 0 ? (
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th style={{ border: '1px solid #ddd', padding: '8px' }}>Date</th>
                  <th style={{ border: '1px solid #ddd', padding: '8px' }}>Name</th>
                </tr>
              </thead>
              <tbody>
                {referrals.map((referral, index) => (
                  <tr key={index}>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>{referral.date}</td>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>{referral.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <Text>No referrals found.</Text>
          )}
        </Box>
      </VStack>
    );
  };

  const AttendeeIntros = () => {
    const [selectedEvent, setSelectedEvent] = useState('');
    const [selectedAttendee, setSelectedAttendee] = useState('');
    const [introductions, setIntroductions] = useState([]);
    const [attendingEvents, setAttendingEvents] = useState([
      { name: 'Event 1', date: '2024-10-01', attendees: ['John Doe', 'Jane Smith'] },
      { name: 'Event 2', date: '2024-11-15', attendees: ['Alice Johnson', 'Bob Brown'] },
      { name: 'Event 3', date: '2024-12-05', attendees: ['Charlie Davis', 'Dana White'] },
    ]);
    const [messageToAttendee, setMessageToAttendee] = useState('');
    const [messageToIntroduced, setMessageToIntroduced] = useState('');

    const handleEventChange = (event) => {
      setSelectedEvent(event.value);
      setSelectedAttendee('');
    };

    const handleAttendeeChange = (attendee) => {
      setSelectedAttendee(attendee.value);
    };

    const handleSubmit = () => {
      if (selectedEvent && selectedAttendee) {
        const alreadyIntroduced = introductions.some(
          intro => intro.event === selectedEvent && intro.attendee === selectedAttendee
        );

        if (!alreadyIntroduced) {
          setIntroductions([...introductions, { event: selectedEvent, attendee: selectedAttendee, date: new Date().toISOString() }]);
          setSelectedEvent('');
          setSelectedAttendee('');
        } else {
          alert('This introduction has already been made.');
        }
      }
    };

    const totalEventsAttended = attendingEvents.length;
    const totalIntroductions = introductions.length;

    return (
      <VStack align="start" spacing={4}>
        <Heading size="md">Introductions</Heading>
        
        <Box mt={4} p={4} borderWidth="1px" borderRadius="md" width="100%" bg="gray.100">
          <Text><strong>Total Events Attended:</strong> {totalEventsAttended}</Text>
          <Text><strong>Total Introductions Made:</strong> {totalIntroductions}</Text>
        </Box>

        <Box width="100%">
          <DropDownListComponent
            dataSource={attendingEvents.map(event => event.name)}
            placeholder="Select Event"
            change={handleEventChange}
            value={selectedEvent}
          />
        </Box>

        {selectedEvent && (
          <Box width="100%">
            <DropDownListComponent
              dataSource={attendingEvents.find(event => event.name === selectedEvent).attendees}
              placeholder="Select Attendee"
              change={handleAttendeeChange}
              value={selectedAttendee}
            />
          </Box>
        )}

        {selectedEvent && selectedAttendee && (
          <Box width="100%">
            <TextAreaComponent
              placeholder="Message to Attendee"
              multiline={true}
              value={messageToAttendee}
              change={({ value }) => setMessageToAttendee(value)}
              style={{ height: 150 }}
              resizeMode='Vertical'
            />
          </Box>
        )}

        {selectedEvent && selectedAttendee && (
          <Box width="100%">
            <TextAreaComponent
              placeholder="Message to Introduced"
              multiline={true}
              value={messageToIntroduced}
              change={({ value }) => setMessageToIntroduced(value)}
              style={{ height: 150 }}
              resizeMode='Vertical'
            />
          </Box>
        )}

        <Button colorScheme="blue" onClick={handleSubmit}>Make Introduction</Button>

        <VStack align="start" spacing={2} width="100%">
          {introductions.map((intro, index) => (
            <Box key={index} p={4} borderWidth="1px" borderRadius="md" width="100%">
              <Text><strong>Event:</strong> {intro.event}</Text>
              <Text><strong>Attendee:</strong> {intro.attendee}</Text>
              <Text><strong>Date:</strong> {new Date(intro.date).toLocaleString()}</Text>
            </Box>
          ))}
        </VStack>
      </VStack>
    );
  };

  const CodeOfConduct = () => {
    const [conflicts, setConflicts] = useState([]);
    const [newConflict, setNewConflict] = useState({
      name: '',
      startedBy: '',
      resolved: false,
      description: ''
    });

    const handleInputChange = (e) => {
      const { name, value, type, checked } = e.target;
      setNewConflict({
        ...newConflict,
        [name]: type === 'checkbox' ? checked : value
      });
    };

    const handleAddConflict = () => {
      setConflicts([...conflicts, newConflict]);
      setNewConflict({
        name: '',
        startedBy: '',
        resolved: false,
        description: ''
      });
    };

    const handleEditConflict = (index) => {
      const conflictToEdit = conflicts[index];
      setNewConflict(conflictToEdit);
      setConflicts(conflicts.filter((_, i) => i !== index));
    };

    const handleDeleteConflict = (index) => {
      setConflicts(conflicts.filter((_, i) => i !== index));
    };

    return (
      <VStack align="start" spacing={6} width="100%" p={4}>
      <Heading size="md">Manage Conflicts</Heading>
      <Box width="100%">
        <DropDownListComponent
        dataSource={profiles.map(profile => profile.name)}
        placeholder="Select Attendee"
        change={({ value }) => setNewConflict({ ...newConflict, name: value })}
        value={newConflict.name}
        />
      </Box>
      <Box width="100%">
        <DropDownListComponent
        dataSource={['this person', 'the other person', 'unknown', 'it\'s complicated']}
        placeholder="Started By"
        change={({ value }) => setNewConflict({ ...newConflict, startedBy: value })}
        value={newConflict.startedBy}
        />
      </Box>
      <Box width="100%">
        <CheckBoxComponent
        label="Resolved"
        name="resolved"
        checked={newConflict.resolved}
        onChange={handleInputChange}
        />
      </Box>
      <Box width="100%">
        <TextAreaComponent
        placeholder="Description"
        name="description"
        value={newConflict.description}
        change={({ value }) => handleInputChange({ target: { name: 'description', value } })}
        style={{ height: 150 }}
        resizeMode='Vertical'
        />
      </Box>
      <Button colorScheme="blue" onClick={handleAddConflict}>Add Conflict</Button>
      <VStack align="start" spacing={4} width="100%">
        {conflicts.map((conflict, index) => (
        <Box 
          key={index} 
          p={4} 
          borderWidth="1px" 
          borderRadius="md" 
          width="100%" 
          bgGradient={conflict.resolved ? "linear(to-r, green.100, green.200)" : "linear(to-r, red.100, red.200)"}
          mb={4}
        >
          <Text><strong>Name:</strong> {conflict.name}</Text>
          <Text><strong>Started By:</strong> {conflict.startedBy}</Text>
          <Text><strong>Resolved:</strong> {conflict.resolved ? 'Yes' : 'No'}</Text>
          <Text><strong>Description:</strong> {conflict.description}</Text>
          <HStack spacing={4} mt={4}>
          <Button colorScheme="yellow" onClick={() => handleEditConflict(index)}>Edit</Button>
          <Button colorScheme="red" onClick={() => handleDeleteConflict(index)}>Delete</Button>
          </HStack>
        </Box>
        ))}
      </VStack>
      </VStack>
    );
  };

  const managementTabs = [
    {
      header: {
        text: 'Profile'
      },
      content: (() => {
        return <Box m={2}>
          <AttendeeProfileInputs />
        </Box>
      }),
    },
    {
      header: {
        text: 'Notes'
      },
      content: (() => {
        return <Box m={2}>
          <ProfileNotes />
        </Box>
      }),
    },
    {
      header: {
        text: 'Events'
      },
      content: (() => {
        return <Box m={2}>
          <AttendeeEvents />
        </Box>
      }),
    },
    {
      header: {
        text: 'Membership'
      },
      content: (() => {
        return <Box m={2}>
          <AttendeeMemberships />
        </Box>
      }),
    },
    {
      header: {
        text: 'Conflict'
      },
      content: (() => {
        return <Box m={2}>
          <AttendeeConflicts />
        </Box>
      }),
    },
    {
      header: {
        text: 'Payments'
      },
      content: (() => {
        return <Box m={2}>
          <AttendeePayments />
        </Box>
      }),
    },
    {
      header: {
        text: 'Referrals'
      },
      content: (() => {
        return <Box m={2}>
          <AttendeeReferrals />
        </Box>
      }),
    },
    {
      header: {
        text: 'Introductions'
      },
      content: (() => {
        return <Box m={2}>
          <AttendeeIntros />
        </Box>
      }),
    },
    {
      header: {
        text: 'Code of Conduct'
      },
      content: (() => {
        return <Box m={2}>
          <CodeOfConduct />
        </Box>
      }),
    }
  ];

  const gridCommandClick = async (args) => {
    const { commandColumn, rowData } = args;
    const { commandHandler, buttonOption } = commandColumn;
    const { content } = buttonOption;

    if(content === 'Details') {
      setProfile(rowData);
      
      Object.keys(profile).forEach(key => {
        setValueAttendeeProfile(key, profile[key]);
      });

      // set profile notes
      const notes = await getProfileNotes(rowData.id);
      setProfileNotes(notes);

      onEditAttendeeOpen();
    }

    if(content === 'Contact') {
      setSelectedAttendee(rowData);
      onContactAttendeeOpen();
    }
  }

  const toolbarOptions = ['Search'];
  const searchOptions = {
      fields: ['name', 'email', 'companyName', 'website', 'description'],
      ignoreCase: true,
      operator: 'contains'
  };

  const ContactAttendeeModal = ({ attendee }) => {
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const handleSendMessage = () => {
      if (message.trim().length > 0) {
        addProfileNote(attendee.id, `Email Sent: ${message}`);

        // Send Email
        crmContactMessage({email: attendee.email, subject, message});

        alert(`Message sent to ${attendee.name}`);
        setMessage('');
        onContactAttendeeClose();
      }
    };

    return (
      <VStack align="start" spacing={4} width="100%">
        <Heading size="md">Contact {attendee.name}</Heading>
        <Text><strong>Email:</strong> {attendee.email}</Text>
        { !!attendee.company_name && <Text><strong>Company:</strong> {attendee.company_name}</Text> }
        <HStack spacing={4} width="100%">
          {attendee.mobile && (
            <Button
              leftIcon={<Icon as={FiPhone} />}
              colorScheme="teal"
              onClick={() => alert(`Calling ${attendee.mobile}`)}
              width="100%"
            >
              Call Mobile: {attendee.mobile}
            </Button>
          )}
          {attendee.landline && (
            <Button
              leftIcon={<Icon as={FiPhoneCall} />}
              colorScheme="teal"
              onClick={() => alert(`Calling ${attendee.landline}`)}
              width="100%"
            >
              Call Landline: {attendee.landline}
            </Button>
          )}
        </HStack>
        <TextBoxComponent
          placeholder="Subject"
          change={({ value }) => setSubject(value)}
          value={subject}
        />

        <TextAreaComponent
          placeholder="Type your message here..."
          multiline={true}
          value={message}
          change={({ value }) => setMessage(value)}
          CssClass="e-bigger"
          style={{ height: 150 }}
          resizeMode='Vertical'
        />
        <Button colorScheme="blue" onClick={handleSendMessage} width="100%">Send Message</Button>
      </VStack>
    );
  };

  return (
    <ChakraProvider theme={theme}>
      <Modal onClose={onEditAttendeeClose} isOpen={editAttendeeOpen} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Manage Attendee</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TabComponent>
              <TabItemsDirective>
                { managementTabs.map((tab, index) => (
                  <TabItemDirective header={tab.header} content={tab.content} key={index}/>
                )) }
              </TabItemsDirective>
            </TabComponent>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onEditAttendeeClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal onClose={onContactAttendeeClose} isOpen={contactAttendeeOpen} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Contact Attendee</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ContactAttendeeModal key={selectedAttendee} attendee={selectedAttendee} />
          </ModalBody>
          <ModalFooter>
            <Button onClick={onContactAttendeeClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
        <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p="4">
          <Text>
          <Heading as='h3' size='lg' mb={4}>Attendees</Heading>
            <br />
            {/* <Button colorScheme='blue' onClick={onEditAttendeeOpen}>Edit Attendee</Button>
            <br /><br /> */}
            <GridComponent dataSource={profiles} commandClick={gridCommandClick} allowPaging={true} pageSettings={{ pageSize: 10 }} toolbar={toolbarOptions} searchSettings={searchOptions}>
              <ColumnsDirective>
                <ColumnDirective field='name' headerText='Name' textAlign='Left'></ColumnDirective>
                <ColumnDirective field='email' headerText='Email' textAlign='Left'></ColumnDirective>
                <ColumnDirective field='company_name' headerText='Company Name' textAlign='Left'></ColumnDirective>
                <ColumnDirective field='website' headerText='Website' textAlign='Left'></ColumnDirective>
                <ColumnDirective field='landline' headerText='Landline' textAlign='Left'></ColumnDirective>
                <ColumnDirective textAlign='Right' commands={[
                    { buttonOption: { content: 'Details', cssClass: 'e-flat' } },
                    { buttonOption: { content: "Contact", cssClass: 'e-flat' } }
                ]}></ColumnDirective>
              </ColumnsDirective>
              <Inject services={[Edit, CommandColumn, Page, Toolbar, Search]}/>
            </GridComponent>
          </Text>
        </Box>
      </Box>
    </ChakraProvider>
  )
}

export default Attendees